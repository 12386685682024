import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Config } from './config/global.values';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private isAdmin = '';
  constructor(
    private router: Router,
    private config: Config,
    private authService: AuthService
    ) {}

  async canActivate(): Promise<boolean> {

    const token = localStorage.getItem(this.config.rseTokenStorage);
    let valid = false;

    if (token) {

      try {
        const response = await this.authService.checkTokenValidity(token).toPromise();
        console.log(`%c 🍧: AuthGuard -> response `, `font-size:20px;background-color:#cff094;color:black;`, response);
        localStorage.setItem(this.config.rseIsLogin, 'true');
        if(response){
          this.isAdmin = (response as any).role == 'admin' ? 'true': 'false';
          localStorage.setItem(this.config.rseIsAdmin, this.isAdmin );
        }
        valid = true;
        console.log( this.router.routerState.snapshot);
       /*  if(this.router.url == '/admin-panel'){
          valid = false;
        } */
        /*  if((response as any).user.id != 20){
          valid = false;
        } */
      } catch (error) {
        valid = false;
        localStorage.setItem(this.config.rseTokenStorage, '');
        localStorage.setItem(this.config.rseUserName, '');
        localStorage.setItem(this.config.rseUserUniversity, '');
        localStorage.setItem(this.config.rseIsLogin, 'false');
        localStorage.setItem(this.config.rseIsAdmin, 'false' );
      }


    } else {
      valid = false;

    }
    console.log(`%c 🐓: AuthGuard -> valid `, `font-size:20px;background-color:#1be162;color:black;`, valid);
    return valid;
  }
}
