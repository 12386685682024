<div class="h-screen">

  <div class="w-full  h-auto flex justify-center">
    <form (ngSubmit)="onSendConsulta( f )" #f="ngForm"
      class="w-11/12 xl:w-6/12 h-48 sm:h-28 md:h-20 xl:h-32 bg-rse-primary mt-5 xl:mt-10 rounded-2xl gap-2 grid xl:flex p-2 xl:p-5 justify-center items-center  grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto">
      <select [(ngModel)]="consulta.categoria" name="categoria" class="rounded-lg h-11 xl:w-3/12 mr-1 ">
        <option value="-1" selected style="display: none;">
          Categoría
        </option>
        <option value="0">
          Proceso
        </option>
        <option value="1">
          Lenguaje
        </option>
        <option value="2">
          Base de datos
        </option>
        <option value="3">
          Software educativo
        </option>
      </select>
      <select *ngIf="consulta.categoria == 0" [(ngModel)]="consulta.processes" name="processes"
        class="rounded-lg h-11 xl:w-3/12 mr-1">
        <option [value]="filtro.id" *ngFor="let filtro of getFiltros()">
          {{filtro.name | titlecase| slice:0:1 }}{{ filtro.name | slice:1 }}
        </option>
        <option selected>
          Todas
        </option>
      </select>
      <select *ngIf="consulta.categoria == 1" [(ngModel)]="consulta.languages" name="languages"
        class="rounded-lg h-11 xl:w-3/12 mr-1">
        <option [value]="filtro.id" *ngFor="let filtro of getFiltros()">
          {{filtro.name | titlecase| slice:0:1 }}{{ filtro.name | slice:1 }}
        </option>
        <option selected>
          Todas
        </option>
      </select>
      <select *ngIf="consulta.categoria == 2" [(ngModel)]="consulta.databases" name="databases"
        class="rounded-lg h-11 xl:w-3/12 mr-1">
        <option [value]="filtro.id" *ngFor="let filtro of getFiltros()">
          {{filtro.name | titlecase| slice:0:1 }}{{ filtro.name | slice:1 }}
        </option>
        <option selected>
          Todas
        </option>
      </select>
      <select *ngIf="consulta.categoria == 3" [(ngModel)]="consulta.filtro" name="filtro"
        class="rounded-lg h-11 xl:w-3/12 mr-1">
        <option [value]="filtro.id" *ngFor="let filtro of getFiltros()">
          {{filtro.name | titlecase| slice:0:1 }}{{ filtro.name | slice:1 }}
        </option>
        <option selected>
          Todas
        </option>
      </select>
      <select *ngIf="consulta.categoria == -1" [(ngModel)]="consulta.filtro" disabled name="filtro"
        class="rounded-lg h-11 xl:w-3/12 mr-1">
        <option selected>
          Todas
        </option>
      </select>
      <div class="rounded-lg h-11 xl:w-5/12 flex bg-rse-light">
        <input placeholder="Escribe aquí para buscar" [(ngModel)]="consulta.material_name" name="material_name" (keydown.enter)="onSendConsulta(f)" type="text"
          class="rounded-l-lg  border-none focus:border-none focus:outline-none focus:box-shadow-none  focus:none bg-rse-light w-11/12">
        <img class="w-8 md:w-9 p-2 cursor-pointer" src="/assets/images/home/find.svg" (click)="onSendConsulta( f )">
      </div>
    </form>
  </div>

    <div class="w-full h-3/6 flex justify-center ">

      <div *ngIf="!isShowResults" class="xl:w-6/12 h-32 mt-10  p-5  text-rse-light  xl:text-lg">
        <div class="relative w-full flex justify-center">
          <p class="md:w-7/12 xl:w-4/5 text-center">
            Encuentra aquí una guía y base para el desarrollo de tus proyectos o comparte con la comunidad tus
            conocimientos, en las siguientes categorías:
          </p>
        </div>
        <div class="relative w-full flex flex-col items-center">
          <div class="grid grid-cols-4  h-24 xl:w-4/5 mt-10 justify-between">
            <div class="w-full h-28  flex justify-center flex-col text-center">
              <img class="h-14" src="/assets/images/home/diagram-project-solid.svg" alt="">
              <h3 class="w-24 self-center">Proceso</h3>
            </div>
            <div class="w-full h-28 flex justify-center flex-col text-center">
              <img class="h-14" src="/assets/images/home/file-code-regular.svg" alt="">
              <h3 class="w-24 self-center">Lenguaje</h3>
            </div>
            <div class="w-full h-28 flex justify-center flex-col text-center">
              <img class="h-14" src="/assets/images/home/database-solid.svg" alt="">
              <h3 class="w-24 self-center">Base de Datos</h3>
            </div>
            <div class="w-full h-28 flex justify-center flex-col text-center">
              <img class="h-14" src="/assets/images/home/shapes-solid.svg" alt="">
              <h3 class="w-24 self-center">Software Educativo</h3>
            </div>
          </div>

        </div>

      </div>

        <div *ngIf="isShowResults" class="w-11/12 relative bg-rse-light-2 rounded-lg xl:w-6/12  mt-10  p-2  md:p-2  text-rse-dark  xl:text-lg">
          <div class="overflow-auto w-full h-full ">
            <div class="grid gap-2 w-full grid-cols-1  justify-center pr-1 pb-11">
              <app-material-card-info [material]="material" *ngFor="let material of materials" class="w-full">

              </app-material-card-info>
            </div>
          </div>
          <div *ngIf="materials == null|| materials.length <=0" class="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div class="flex flex-col items-center justify-center" >
              <img class="w-12" src="/assets/images/utils/frog-solid.svg" alt="error 404">
              <p class="text-center text-rse-dark">Sin resultados</p>
            </div>
          </div>
          <div class=" flex  md:flex-wrap absolute bottom-0 right-0 md:right-5 w-auto md:w-auto overflow-x-auto p-2">
            <a routerLink="/upload" >
              <app-btn-icon bgColor="rse-primary"  textSize="text-base" > Cargar material nuevo<img class="ml-2" src="/assets/images/utils/arrow-up-from-bracket-solid-white.svg" alt="" srcset=""></app-btn-icon>
            </a>
          </div>
        </div>
    </div>

</div>


