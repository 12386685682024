import { Component } from '@angular/core';
import { WebRepositoryService } from '../../modules/shared/services/web-repository.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {

  public user: {
    email:  string
  } = {email:''};

  constructor ( private webRepoServ: WebRepositoryService){

  }

  public onSendEmail(form: any){
  console.log(`%c 🇹🇭: ForgotPasswordComponent -> onSendEmail -> form `, `font-size:20px;background-color:#281359;color:white;`, form.value);
  const user = form.value;
    this.webRepoServ.forgotPassword(user).subscribe(
      response => {
        Swal.fire({
          title: 'Recuperacion de contraseña en proceso',
          text: 'Enviaremos un correo electrónico al correo que nos proporcionaste.',
          icon: 'info',
          confirmButtonText: 'Ok'
        });
      },
      error => {
        Swal.fire({
          title: 'Recuperacion de contraseña en proceso',
          text: 'Verifica que el correo sea correcto.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    );
  }
}
