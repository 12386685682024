import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { LandingComponent } from './pages/landing/landing.component';
import { MaterialModule } from './modules/material/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { Config } from './config/global.values';
import { SharedModule } from './modules/shared/shared.module';
import { PageTestComponent } from './pages/page-test/page-test.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetPasswordComponentComponent } from './pages/ResetPasswordComponent/ResetPasswordComponent.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    LandingComponent,
    PageTestComponent,
    ResetPasswordComponentComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,


  ],
  exports:[

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    Config
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
