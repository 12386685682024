import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Material } from '../../../models/material';
import { WebRepositoryService } from '../../../shared/services/web-repository.service';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Config } from '../../../../config/global.values';
import Swal from 'sweetalert2'
import { dataForUniversitiesLabels } from '../../../user/config/empy-values-universities';
@Component({
  selector: 'app-material-view',
  templateUrl: './material-view.component.html',
  styleUrls: ['./material-view.component.scss']
})
export class MaterialViewComponent implements OnInit {

  @Input() material: Material = {};

  public apiUrl: string ='';

  public labesAux: any[]= [];

  public showMetrics : boolean = false;
  public isAdmin: boolean = false;

  public materialId!: number ;

  public generalDownload = dataForUniversitiesLabels;

  public filesMap: {
    url:string,
    name:string,
    file?: any
  }[]=[];

  public showOptions : boolean = false;
  constructor(
      private route: ActivatedRoute,
      private webRepositoryService: WebRepositoryService,
      private sanitizer: DomSanitizer,
      private config: Config,

    ) {

    }

  ngOnInit(){
    this.materialId = this.route.snapshot.params['id'];
    this.getMaterial(this.materialId);
    this.apiUrl = environment.apiUrl;
     this.isAdmin = localStorage.getItem(this.config.rseIsAdmin) =='true';
     const isOwner= parseInt(localStorage.getItem(this.config.rseUserId) || '0', 10) == this.material.user?.id;
     if(this.isAdmin || isOwner){
      this.showOptions = true;
     }
  }

  async downloadedMaterial(url: string){
    /* this.webRepositoryService.getFile(url).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
      return safeUrl;
    });
     */
    const url1 = url;
    let pdfSrc: any = null;
    let safeUrl;
    try {
      const response = await this.webRepositoryService.getFile(url1).toPromise();
      if(response){
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);

      }
      pdfSrc = safeUrl;
    } catch (error) {
      console.error(error);
    }
    return pdfSrc;
  }

  private getMaterial(id: number){
    this.webRepositoryService.findMaterial(id).subscribe({
      next: (value) =>{
      this.material = value.data;
      this.labesAux.push(...this.material.databases as any);
      this.labesAux.push(...this.material.languages as any);
      this.labesAux.push(...this.material.processes as any);
      console.log(`%c 👁️‍🗨️: MaterialViewComponent -> getMaterial -> this.labesAux `, `font-size:20px;background-color:#55e0c0;color:black;`, this.labesAux);

      if(this.material.filesUrl){
        this.material.filesUrl.map(file=>{

            this.filesMap.push({
              name:
              file.original_name
              ||
              file.name
              ,
              url: `/file/${file.id}`
            });
        });
      }
    },
      error: () =>{

      }
    })
  }

  public onShowMetrics(){
    this.showMetrics= true;
  }

  public onCloseMetrics(evnt: any){
    this.showMetrics= false;
  }

  public onDelete(){

    Swal.fire({
  title: '¿Estás seguro?',
  text: '¿Quieres eliminar este material?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Sí, eliminar',
  cancelButtonText: 'Cancelar'
}).then((result) => {
  if (result.isConfirmed) {
    // Acción a realizar si el usuario confirma la eliminación
    this.webRepositoryService.deleteMaterial(this.materialId).subscribe({
      next:(value)=>{
        Swal.fire({
          title: 'El material se elimino!',
          text: 'El material ya no esta disponible',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        this.material = {};
        this.showOptions = false;
      },
      error:(error)=>{
        Swal.fire({
          title: 'Error!',
          text: 'Puedes intentarlo más tarde',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    });
  }
})

  }

}
