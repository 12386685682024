
 <button
 (click)="changeOpen()"
 cdkOverlayOrigin
 type="button"

 #iconOverlay="cdkOverlayOrigin"
 class="flex rounded-full focus:bg-white/[.22]  md:flex-wrap w-10 h-10 justify-center  items-center">
  <img src="/assets/images/utils/ellipsis-vertical-solid.svg" alt="">
</button>
<ng-template
cdkConnectedOverlay
[cdkConnectedOverlayHasBackdrop]="true"
cdkConnectedOverlayBackdropClass ='cdk-overlay-transparent-backdrop',
[cdkConnectedOverlayOrigin]="iconOverlay"
[cdkConnectedOverlayOpen]="isOpen"
(backdropClick)="closeOverlay()"

>
<div class="bg-rse-light-2  z-50 my-1 text-base list-none rounded  divide-gray-100 shadow text-rse-dark w-auto h-auto p-3 px-0 ">
  <ng-content></ng-content>
</div>

</ng-template>
