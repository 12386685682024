<button
        type="button"
        class="flex items-center"
        aria-expanded="false"
        type="button"
        (click)="changeOpen()"
        cdkOverlayOrigin
        #iconOverlay="cdkOverlayOrigin"
      >
      <img [src]="infoIcon">
 </button>
<ng-template
cdkConnectedOverlay
[cdkConnectedOverlayOrigin]="iconOverlay"
[cdkConnectedOverlayHasBackdrop]="true"
[cdkConnectedOverlayOpen]="isOpen"
(backdropClick)="closeOverlay()"
>
<div class="bg-white  text-rse-dark w-44 flex justify-center  h-auto p-3 rounded-lg shadow-md">
  <p class="p-2 overflow-auto w-full h-full text-sm">
    {{text  | titlecase | slice:0:1 }}{{ text  | slice:1 }}


  </p>
</div>

</ng-template>
