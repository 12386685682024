import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.scss']
})
export class SelectMultiComponent implements OnInit {

  @Input() items: { name: string, checked?: boolean, id?: number }[] = [];

  selectedItems: { name: string, checked?: boolean, id?: number  }[] = [];

  dropdownOpen = false;

  @Output() selectedValues = new EventEmitter<number[]>();

  constructor() { }

  ngOnInit() {

    /* const selectBtn = document.querySelector("#select-btn"),
      items = document.querySelectorAll(".item");

    selectBtn!.addEventListener("click", () => {
      selectBtn!.classList.toggle("open");
    });

    items.forEach(item => {
      item.addEventListener("click", () => {
        item.classList.toggle("checked");

        let checked = document.querySelectorAll(".checked"),
          btnText = document.querySelector(".btn-text") as any;

        if (checked && checked.length > 0) {
          btnText!.innerText = `${checked.length} seleccionados`;
        } else {
          btnText.innerText = "";
        }
      });
    }) */
    setTimeout(() => {
      this.selectedItems = this.items.filter(item => item.checked);
    }, 2000);




     document.addEventListener('click', (event) => {
      if (!(event.target as HTMLElement).closest('.my-container')) {
        this.cerrarDropdown();
      }
    });
  }


  cerrarDropdown() {
    this.dropdownOpen = false;
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectItem(item: { name?: string, checked?: boolean, id?: number }) {
    item.checked = !item.checked;
    this.selectedItems = this.items.filter(item => item.checked);
    let values = this.selectedItems.map<number>(value=>{
      return value.id || 0
    });
    this.selectedValues.emit(values);
  }

}
