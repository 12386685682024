<div *ngIf="showModal"  class="fixed inset-0 z-50  bg-rse-dark bg-opacity-50">
  <div class="relative w-full flex justify-center  max-w-full mx-auto my-6 h-screen">
    <div class="flex w-5/5 md:w-2/5 text-sm absolute top-0 ">
      <button class="p-2"><img src="/assets/images/utils/circle-xmark-regular-color.svg" (click)="onCloseModal()" alt=""></button>
    </div>
    <div class="bg-rse-light w-5/5 h-2/3  md:w-2/5 p-4 pt-10 rounded-lg shadow-lg ">

      <div  class="h-full  overflow-y-auto">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
