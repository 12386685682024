import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  public selectedOptions: string[] = [];

  onSelectOption(checked: any , option: string) {
    let checked2 = checked.target.checked
    if (checked2) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions = this.selectedOptions.filter(o => o !== option);
    }
  }

}
