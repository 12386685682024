import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  checkTokenValidity(token: string) {
    //localStorage.setItem(this.config.rseUserName, value.user_name);
    //localStorage.setItem(this.config.rseUserUniversity, value.user_university);


    return this.http.post(`${this.apiUrl}/check-token-validity`, { token });
  }
}
