import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AuthGuard } from './auth.guard';
import { AuthInGuard } from './auth-in.guard';
import { PageTestComponent } from './pages/page-test/page-test.component';
import { AdminGuard } from './auth-admin.guard';
import { ResetPasswordComponentComponent } from './pages/ResetPasswordComponent/ResetPasswordComponent.component';
export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'Iniciar sesión',
    canActivate: [AuthInGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    title: 'Registro',
    canActivate: [AuthInGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'Recuperar contraseña'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/material/material.module').then(m => m.MaterialModule),
    title: 'Home',
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-panel',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    title: 'Panel administración',
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: LandingComponent,
    title: 'Respositorio de software educativo',
  },
  {
    path: 'test',
    component: PageTestComponent,
    title: 'Respositorio de software educativo',
  },
  {
    path:'reset-password',
    component: ResetPasswordComponentComponent,
    title: 'Respositorio de software educativo',
  },

];



/* let routesString: string = '';
export  const myRoutes = (routes as any[] ).reduce((acc, curr) => {
  if (curr.path != null) {
    acc[curr.path] = curr;
  }
  return acc;
}, {});

routes.map(route=>{
  routesString = routesString + '/' + route.path
})
console.log(myRoutes); */



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
