/* export const descriptionsInputsForm ={
  "title": "título del material educativo",
  "creation_date": "fecha de creación del material",
  "description": "descripción breve del material educativo",
  "version_number": "número de versión del material",
  "display_type": "tipo de pantalla para el material",
  "interface": "detalles de la interfaz del material",
  "services": "detalles de los servicios del material",
  "data_source": "detalles de la fuente de datos del material",
  "preconditions": "detalles de las condiciones previas para el uso del material",
  "functionality": "detalles de las funciones y características del material",
  "system": "detalles del sistema operativo necesario para el uso del material",
  "github_link": "enlace al repositorio de Github del material",
  "files": "archivo(s) asociado(s) al material",
  "process": "detalles sobre el proceso de creación del material",
  "languages": "lenguajes de programación utilizados en el material",
  "databases": "bases de datos utilizadas en el material",
  "type": "tipo de material educativo",
  "reutility": "detalles sobre la reutilización del material",
  "distribution": "detalles sobre la distribución del material",
  "distribution_agreement_file": "archivo que contiene el acuerdo de distribución del material"
}

 */

export const descriptionsInputsForm ={
  "title": "Ingresa el título del material educativo. Por ejemplo: 'Introducción a la programación en Python'.",
  "creation_date": "Ingresa la fecha de creación del material.",
  "description": "Ingresa una breve descripción del contenido del material educativo. Por ejemplo: 'Este curso introductorio de programación en Python te enseñará los conceptos básicos de la programación y cómo aplicarlos en proyectos reales'.",
  "version_number": "Ingresa el número de versión del material. Por ejemplo: 1.0",
  "display_type": "Selecciona o describe el tipo de pantalla para la presentación del material. Por ejemplo: 'Página web con vídeos y ejercicios'.",
  "interface": "Selecciona o describe detalles sobre la interfaz del material, como los componentes y su distribución. Por ejemplo: 'La pantalla principal consta de una barra de navegación y una sección de contenido principal con vídeos y ejercicios'.",
  "services": "Ingresa detalles sobre los servicios o funciones que ofrece el material educativo. Por ejemplo: 'Los estudiantes tendrán acceso a un foro de discusión en línea para hacer preguntas y colaborar con otros estudiantes'.",
  "data_source": "Ingresa detalles sobre la fuente de datos del material educativo, como archivos externos o bases de datos. Por ejemplo: 'El material incluye una base de datos de ejemplo para practicar la programación de bases de datos'.",
  "preconditions": "Ingresa detalles sobre las condiciones previas que deben cumplirse para utilizar el material educativo. Por ejemplo: 'Este curso requiere conocimientos básicos de programación y matemáticas'.",
  "functionality": "Ingresa detalles sobre las funciones y características del material educativo, como la capacidad de búsqueda o la interactividad. Por ejemplo: 'El material incluye una función de búsqueda para encontrar contenido específico y una sección de preguntas frecuentes para responder a las preguntas más comunes'.",
  "system": "Ingresa detalles sobre el sistema operativo necesario para utilizar el material educativo. Por ejemplo: 'Este material se puede utilizar en cualquier sistema operativo, incluyendo Windows, macOS y Linux'.",
  "github_link": "Ingresa el enlace al repositorio de Github que contiene el código fuente del material educativo. Por ejemplo: 'https://github.com/username/repo'.",
  "files": "Selecciona y carga los archivos asociados al material educativo, por el momento soportamos formato PDF, Por ejemplo: 'Selecciona el archivo de diagrama de flujo problema de programación 1'. Puedes agregar varios archivos. Todo el material estará sujeto a las condiciones descritas en la cesión de derechos.",
  "process": "Ingresa detalles sobre el proceso de creación del material educativo, como el método de desarrollo y los participantes. Por ejemplo: 'Este material se creó utilizando un enfoque basado en proyectos con un equipo de programadores y diseñadores gráficos'.",
  "languages": "Ingresa los lenguajes de programación utilizados en la creación del material educativo. Por ejemplo: 'Python, HTML, CSS'.",
  "databases": "Ingresa las bases de datos utilizadas en la creación del material educativo. Por ejemplo: 'MySQL, PostgreSQL'.",
  "type": "Selecciona el tipo de material educativo. Por ejemplo: 'Software Educativo'.",
  "reutility": "Ingresa detalles sobre la reutilización del material, como la licencia o los términos de uso. Por ejemplo: 'Este material se encuentra bajo una licencia Creative Commons, lo que significa que se puede reutilizar y distribuir con atribución'.",
  "distribution": "Ingresa detalles sobre la distribución del material, como el público objetivo y la plataforma de distribución. Por ejemplo: 'Este material está dirigido a estudiantes de programación y se distribuirá a través de una plataforma de aprendizaje en línea'.",
  "distribution_agreement_file": "Selecciona y carga el archivo que contiene el acuerdo de distribución del material. Selecciona el archivo PDF que contiene el acuerdo de distribución del material previamente firmado.",
  "check_agreement_file": "Lee el documento de cesión de derechos y haz click en 'He leído la cesión de derechos adjunta en este espacio', es necesario marcar la casilla para poder guardar tu material."
}
