<div class="relative rounded my-container">
  <div class="select-btn rounded" [ngClass]="{ 'open': dropdownOpen }" (click)="toggleDropdown()">
    <span class="btn-text">{{ selectedItems.length > 0 ? selectedItems.length + ' Seleccionados' : '' }}</span>
    <span class="arrow-dwn" [ngClass]="{ 'open': dropdownOpen }">
      <img src="/assets/images/utils/chevron-down.svg" alt="" srcset="">
    </span>
  </div>
  <ul class="list-items h-40 overflow-y-auto rounded" [ngClass]="{ 'open': dropdownOpen }">
    <li class="item rounded" *ngFor="let item of items" (click)="selectItem(item)" [ngClass]="{ 'checked': item.checked }">
      <span class="checkbox">
        <img [ngClass]="{ 'show': item.checked }"  src="/assets/images/utils/check-solid.svg">
      </span>
      <span class="item-text">{{ item.name }}</span>
    </li>
  </ul>
</div>
