import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebRepositoryService } from '../../../../shared/services/web-repository.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../../../../environments/environment';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';

@Component({
  selector: 'app-pdf-file-view',
  templateUrl: './pdf-file-view.component.html',
  styleUrls: ['./pdf-file-view.component.css']
})
export class PdfFileViewComponent implements OnInit {

  public url: string= 'http://localhost:8000/api/storage/documents_files/031110-7-2 de 3.pdf';

  public filename: string = 'custom-name.pdf';

  public isLoadedFile: boolean = false;
  pdfSrc: any = null!;
  pdfData: any;
  public progress: number = 0
  pdf: any;
  pageNumber: number = 1;
  totalPages: number = null!;
  @ViewChild('pdfViewer') pdfViewer: ElementRef = null!;

  private id: number = null!;
  constructor( private route: ActivatedRoute,
     private webRepositoryService: WebRepositoryService,
     private sanitizer: DomSanitizer) { }

  ngOnInit() {
    GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.js';
    this.id = this.route.snapshot.params['id'];
    /* this.webRepositoryService.findFile(this.id).subscribe((response: ArrayBuffer ) => {
      this.pdfData = response;
      const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        let safeUrl = null;
        safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.pdfSrc = safeUrl;
       // this.pdfData = safeUrl;
      this.loadPDF();
    }); */

    this.webRepositoryService.findFile(this.id).subscribe((response: ArrayBuffer | null) => {

      // Calcular el tamaño del archivo

      if(response instanceof ArrayBuffer){
        const reader = new FileReader();

        // Cuando se termina de cargar el contenido del ArrayBuffer en el FileReader, cargar el PDF
        reader.onloadend = () => {
          // Cargar el contenido del ArrayBuffer en un Blob
          const blob = new Blob([response!], { type: 'application/pdf' });

          // Crear un objeto URL para el Blob
          const url = URL.createObjectURL(blob);

          // Asignar la URL segura al objeto pdfSrc
          let safeUrl = null;
          safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
          this.pdfSrc = safeUrl;

          // Asignar el valor del ArrayBuffer a pdfData
          this.pdfData = response;

          // Cargar el PDF
          this.loadPDF();
        };

        // Leer el contenido del ArrayBuffer como un objeto Blob
        reader.readAsArrayBuffer(new Blob([response!]));
      }else{
        if(response!==null){
          const percentDone = Math.round((100 * response['loaded']) / response['total']);
          console.log(`Progreso de descarga: ${percentDone}%`);
          this.progress= percentDone;
        }

      }


    });



    this.webRepositoryService.findFileName(this.id).subscribe(response => {
      this.filename = response.data;
    });
  }

  loadPDF() {
    pdfjsLib.getDocument({ data: this.pdfData }).promise.then(pdf => {
      this.pdf = pdf;
      this.totalPages = pdf.numPages;

      this.loadPage(this.pageNumber);
    });
  }

  loadPage(pageNumber: number) {
    this.pdf.getPage(pageNumber).then((page: any) => {
      const canvas = this.pdfViewer.nativeElement as HTMLCanvasElement;
      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const context = canvas.getContext('2d');
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      this.isLoadedFile= true;
      page.render(renderContext);

    });
  }

  nextPage() {
    if (this.pageNumber < this.totalPages) {
      this.pageNumber++;
      this.loadPage(this.pageNumber);
    }
  }

  prevPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.loadPage(this.pageNumber);
    }
  }

  public registerDownload(){
    this.webRepositoryService.registerDownloadFile(this.id).subscribe({
      next:  (result)=> {
      console.log(`%c 4️⃣: registerDownload -> result `, `font-size:20px;background-color:#81beb9;color:white;`, result);

      },
      error: ()=> {

      }
    });
  }


}
