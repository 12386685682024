<div *ngIf="material.title" class="w-11/12 xl:w-6/12 relative h-26 sm:h-30 md:h-44 xl:h-48 bg-rse-primary mt-5 xl:mt-10 rounded-2xl gap-2 grid xl:flex p-2 xl:p-5 xl:pr-1 mx-auto">

  <app-overlay-options *ngIf="showOptions" class=" absolute top-0 right-0 m-3 ">
    <div class="grid grid-rows-3 gap-2 text-sm">
      <a routerLink="/upload" class="hover:bg-slate-600/[.22] px-3">
        Cargar material nuevo
      </a>
      <a [routerLink]="'/update/'+materialId" class="hover:bg-slate-600/[.22] px-3">
        Actualizar material
      </a>
      <a class="text-red-800 hover:bg-slate-600/[.22] px-3 cursor-pointer" (click)="onDelete()" >
        Eliminar material
      </a>
    </div>

  </app-overlay-options>
  <div class="text-rse-light items-baseline w-full">
    <h2 class="text-sm xl:text-lg font-semibold">
      {{material.title}}
      <span *ngIf="material.version_number" class="text-xs xl:text-base  font-thin">
        V{{material.version_number}}
      </span>
    </h2>
    <h3 class="text-sm xl:text-base ">
      {{material.user?.name}} {{material.user?.last_name}}
    </h3>
    <h3 class="text-sm xl:text-base ">
      {{ material.downloads }} descargas desde que se publicó
    </h3>
    <h3 class="text-sm xl:text-base ">
      Fecha de creación {{material.creation_date | date:'shortDate'}}
    </h3>
    <div class="flex flex-wrap mt-3">
    <!-- <app-btn-icon
      bgColor="rse-primary"
      borderColor="rse-light"
      textSize="text-sm"
      class="mr-2 flex my-3"
      >Descarga de archivos
      <img class="ml-1 w-3" src="/assets/images/utils/arrow-down-solid.svg">
    </app-btn-icon> -->

    <a *ngIf="material.github_link | githubLinkValidation" [href]="material.github_link " class="flex flex-wrap items-center justify-center" target="_blank" rel="noopener noreferrer">
      <app-btn-icon
      bgColor="rse-primary"
      borderColor="rse-light"
      textSize="text-sm"
      class="mr-2 flex my-3">
      Accede al código en GitHub
      <img class="ml-1 w-5" src="/assets/images/github.svg">
    </app-btn-icon>
    </a>
    <a *ngIf="(material.github_link | githubLinkValidation) == false" class="flex flex-wrap items-center justify-center" target="_blank" rel="noopener noreferrer">
      <app-btn-icon
      bgColor="rse-primary"
      borderColor="rse-light"
      textSize="text-sm"
      class="mr-2 flex my-3">
      Accede al código en GitHub
      <img class="ml-1 w-5" src="/assets/images/github.svg">
    </app-btn-icon>
    </a>
    <app-btn-icon
      bgColor="rse-primary"
      borderColor="rse-light"
      textSize="text-sm"
      class="flex my-3"
      *ngIf="isAdmin"
       (click)="onShowMetrics()"
      >Métricas
      <img class="ml-1 w-4 " src="/assets/images/chart-simple-solid.svg">
    </app-btn-icon>
    </div>
  </div>
</div>
<div class="h-screen flex justify-center">
  <div *ngIf="material.title" class="w-11/12 overflow-auto xl:w-6/12 h-5/6 md:h-5/6 xl:h-5/6 bg-rse-light mt-5 xl:mt-5 rounded-2xl  p-2 xl:p-5 ">
    <div class="block">
      <span class="font-semibold text-rse-dark text-lg">
        Acerca del Material
      </span>
      <h2 class="font-light">
        {{material.description| titlecase | slice:0:1 }}{{ material.description | slice:1 }}
      </h2>
    </div>
    <div class="mt-5 rounded-xl h-auto  p-2 border-2 border-rse-dark">
      <h4 class="mb-2 text-rse-dark font-semibold">{{'Habilidades aplicadas' | uppercase}}</h4>
      <div class="flex flex-wrap">
        <app-tag *ngFor="let label of labesAux" class="m-1"  [label]="label.name"></app-tag>

      </div>
    </div>

    <div class="mt-2 md:mt-0 md:flex w-full">
      <div class="md:w-8/12 block">
        <div class="mt:2 md:mt-5 rounded-xl h-auto  p-2 border-2 border-rse-dark">
          <h4 class="mb-2 text-rse-dark font-semibold">{{'Archivos' | uppercase}}</h4>
          <ng-container *ngIf="filesMap && filesMap.length > 0">
            <div class="" *ngFor="let file of filesMap">
              <a [routerLink]="file.url" class="flex items-center hover:bg-zinc-300" >
               <img src="/assets/images/file-pdf-solid-red.svg" class="mx-1" alt=""> {{file.name}}<!--  <img class="m-2" src="/assets/images/utils/circle-down-solid-medium.svg"> -->
              </a>
            </div>
          </ng-container>


        </div>
        <div class="grid md:grid-cols-3 mt-2">
          <div class="block">
            <h4 class="font-semibold">Grado de reutilización</h4>
            <span>
              {{material.reutility| titlecase | slice:0:1 }}{{ material.reutility  | slice:1 }}
            </span>
          </div>

          <div class="block">
            <h4 class="font-semibold">Distribución de software</h4>
            <span>
              {{material.distribution| titlecase | slice:0:1 }}{{ material.distribution  | slice:1 }}
            </span>
          </div>

        </div>
      </div>
      <div class="w-full md:w-4/12 md:ml-2">
        <div class="md:mt-5  h-auto  md:p-2 ">
          <h4 class="mb-2 text-rse-dark font-semibold">{{'Tipo de visualización' | uppercase}}</h4>
          <p>
            {{material.display_type  | titlecase | slice:0:1 }}{{ material.display_type  | slice:1 }}
          </p>
        </div>
        <div class="md:mt-5  h-auto  md:p-2 ">
          <h4 class="mb-2 text-rse-dark font-semibold">{{'Interfaz' | uppercase}}</h4>
          <p>
            {{material.interface  | titlecase | slice:0:1 }}{{ material.interface | slice:1 }}
          </p>
        </div>
        <div class="md:mt-5  h-auto  md:p-2 ">
          <h4 class="mb-2 text-rse-dark font-semibold">{{'Fuente de datos' | uppercase}}</h4>
          <p>
            {{material.data_source  | titlecase | slice:0:1 }}{{ material.data_source | slice:1 }}
          </p>
        </div>
        <div class="md:mt-5  h-auto  md:p-2 ">
          <h4 class="mb-2 text-rse-dark font-semibold">{{'Servicios' | uppercase}}</h4>
          <p>
            {{material.services  | titlecase | slice:0:1 }}{{ material.services | slice:1 }}
          </p>
        </div>
        <div class="md:mt-5  h-auto  md:p-2 ">
          <h4 class="mb-2 text-rse-dark font-semibold">{{'Precondiciones' | uppercase}}</h4>
          <p>
            {{material.preconditions  | titlecase | slice:0:1 }}{{ material.preconditions  | slice:1 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal [showModal]="showMetrics" (dataEvent)="onCloseMetrics($event)" >

  <div class="flex flex-col items-center">

    <div>
       <h3 class="text-lg text-rse-dark text-center">
        DESCARGAS POR SEMESTRE
       </h3>
      <app-chart-bar
    [labels]="{horizontal:'Semestre',vertical:'Número de descargas'}" ></app-chart-bar>
    </div>

    <div>
      <h3 class="text-lg text-rse-dark text-center">
       DESCARGAS POR UNIVERSIDAD
      </h3>
     <app-chart-bar
   [labels]="{horizontal:'Semestre',vertical:'Número de descargas'}" ></app-chart-bar>
    </div>

    <div>
      <h3 class="text-lg text-rse-dark text-center">
       DESCARGAS EN EL AÑO
      </h3>
     <app-chart-bar
   [labels]="{horizontal:'Semestre',vertical:'Número de descargas'}"  [showLegend]="true" [data]="generalDownload" ></app-chart-bar>
    </div>
  </div>


</app-modal>
