import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRequiredField]'
})
export class RequiredFieldDirective {
  constructor(private el: ElementRef) {
    const input = this.el.nativeElement;
    const label = document.createElement('label');

    if (input.required) {
      label.innerText = 'Este campo es requerido.';
      //label.classList.add('text-red-500');
      input.parentElement.appendChild(label);
      label.classList.add('hidden');
      label.classList.add('text-sm');
      label.classList.add('text-end');

      input.addEventListener('blur', () => {
        if ( !input.touched  && input.value != '') {
          input.classList.remove('border-red-500');
          label.classList.add('hidden');
        } else {
          input.classList.add('border-red-500');
          label.classList.remove('hidden');
        }
      });
    }
  }
}
