import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'githubLinkValidation'
})
export class GithubLinkValidationPipe implements PipeTransform {
  transform(value: string | undefined): boolean {
    const regex = /^https?:\/\/github\.com\/[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,38}[a-zA-Z0-9])?\/[a-zA-Z0-9._-]+$/;
    return regex.test(''+value);
  }
}
