
<div class="w-full h-full ">
  <div class=" w-11/12 xl:w-3/12 m-auto">
    <header class="pt-6 pb-2 flex justify-center items-center flex-col">
      <div class="w-40 h-40 rounded-full bg-[#070707] flex justify-center p-1">
        <img src="/assets/images/logo/logo.svg" alt="logo" class="w-32">
      </div>
      <h1 class="text-center text-rse-light font-semibold text-2xl pt-4 pb-8">
        Restablece tu contraseña
      </h1>
    </header>
    <div class="bg-rse-primary rounded shadow pt-4 pb-2 px-3 xl:px-12">
      <div class="mb-4">
        <form (ngSubmit)="onSavePassword( f )" #f="ngForm"  novalidate class="space-y-4">
          <div class="">
            <label for="pass" class="text-rse-light text-lg">
              Contraseña nueva
            </label>
            <input name="password1" minlength="8" [(ngModel)]="user.password1"  type="password" class="w-full bg-gray-100 rounded border-2 border-gray-300">
          </div>
          <div class="">
            <label for="pass2" class="text-rse-light text-lg">
              Confirmar contraseña
            </label>
            <input name="password2" minlength="8"  [(ngModel)]="user.password2"  type="password" class="w-full bg-gray-100 rounded border-2 border-gray-300">
            <span class="m-2 text-sm font-medium text-white"  *ngIf="user.password2 && user.password2.length >= 0 && user.password2 != user.password1">
              Las contraseñas no coinciden.
            </span>
            <br>
            <span class="m-2 text-sm font-medium text-white"  *ngIf="user.password2 && user.password2.length < 8 && user.password1 && user.password1.length < 8 ">
              La contraseña debe tener por lo menos 8 caracteres.
            </span>
          </div>
          <div>
            <app-btn  [disabled]="!f.valid ||  user.password2 != user.password1" typeBtn="submit"  bgColor="rse-medium" textColor="rse-light">Restablecer</app-btn>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
