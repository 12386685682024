import { Component, OnInit, Input } from '@angular/core';
import { Material } from '../../../models/material';

@Component({
  selector: 'app-material-card-info',
  templateUrl: './material-card-info.component.html',
  styleUrls: ['./material-card-info.component.scss']
})
export class MaterialCardInfoComponent implements OnInit {

  @Input()
  material: Material = {};

  @Input()
  labels: string[] = [];
  constructor() { }

  ngOnInit() {
    let labelsAux: any[] = [];

    if(this.material.languages){
      labelsAux.push(...this.material.languages as any);
    }
    if(this.material.databases){
      labelsAux.push(...this.material.databases as any);
    }
    if(this.material.processes){
      labelsAux.push(...this.material.processes as any);
    }

    for (let label of labelsAux as any){
      this.labels.push(label.name)
    }

  }



}
