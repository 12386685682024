import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebRepositoryService } from '../../modules/shared/services/web-repository.service';

@Component({
  selector: 'app-ResetPasswordComponent',
  templateUrl: './ResetPasswordComponent.component.html',
  styleUrls: ['./ResetPasswordComponent.component.scss']
})
export class ResetPasswordComponentComponent implements OnInit {

  public user: any = {};
  public token: string | null = '';


  constructor(private route: ActivatedRoute, private webRepositoryService :WebRepositoryService) { }

  ngOnInit() {

    this.token =  this.route.snapshot.queryParamMap.get('token');
  }

  public onSavePassword(form: any) {
    const password = form.value.password1;
    const payload = {
      password: password,
      token: this.token
    };

    this.webRepositoryService.resetPassword(payload).subscribe({
      next: (res) => {

      },
      error: (err: any) => {

      }
    })

  }

}
