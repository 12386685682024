import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BtnComponent } from './components/btn/btn.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { BtnIconComponent } from './components/btn-icon/btn.component';
import { TagComponent } from './components/tag/tag.component';
import { SelectMultiComponent } from './components/select-multi/select-multi/select-multi.component';
import { RequiredFieldsDirectiveDirective } from './directives/required-fields-directive.directive';
import { RequiredFieldDirective } from './directives/requiered-input-field/requiered-input-fild';
import { OverlayInfoComponent } from './components/overlay-info/overlay-info.component';
import { GithubLinkValidationPipe } from './pipes/githubLinkValidation.pipe';
import { SelectComponent } from './components/select/select.component';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartBarComponent } from './components/chart-bar/chart-bar.component';
import { ModalComponent } from './components/modal/modal.component';
import { OverlayOptionsComponent } from './components/overlay-options/overlay-options.component';

@NgModule({
  declarations: [
    NavbarComponent,
    BtnComponent,
    BtnIconComponent,
    TagComponent,
    SelectMultiComponent,
    OverlayInfoComponent,
    SelectComponent,
    ChartBarComponent,
    ModalComponent,
    OverlayOptionsComponent,

    RequiredFieldsDirectiveDirective,
    RequiredFieldDirective,

    GithubLinkValidationPipe
  ],
  imports: [
    RouterModule,
    CommonModule,
    OverlayModule,
    FormsModule,
    NgxChartsModule,

  ],
  exports: [
    BtnComponent,
    NavbarComponent,
    BtnIconComponent,
    TagComponent,
    SelectMultiComponent,
    OverlayInfoComponent,
    SelectComponent,
    ChartBarComponent,
    ModalComponent,
    OverlayOptionsComponent,
    RequiredFieldsDirectiveDirective,
    RequiredFieldDirective,

    GithubLinkValidationPipe
  ]
})
export class SharedModule { }
