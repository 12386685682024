export const dataForUniversitiesLabels =
[
  {
    name: 'Enero',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Febrero',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Marzo',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Abril',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Mayo',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Junio',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Julio',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Agosto',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Septiembre',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Octubre',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Noviembre',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  },
  {
    name: 'Diciembre',
    series: [
      {
        name: 'UAA',
        value: 0,
      },
      {
        name: 'UNACH',
        value: 0,
      },
      {
        name: 'UAQ',
        value: 0,
      },
      {
        name: 'TODAS',
        value: 0,
      },
    ],
  }
];
