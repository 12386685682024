<div class="h-4/6  xl:h-3/4 w-full bg-rse-dark xl:flex">
  <div class="h-1/2 xl:h-full w-full xl:w-1/2 p-4 md:p-3 xl:p-10 text-rse-light md:text-xl xl:text-lg">
    <div class="w-full xl:w-10/12">
      <p class="mt-3 xl:mt-8">
        Bienvenido al "Repositorio de Software Educativo" (RSE), el solucionador de necesidades educativas en el ámbito digital. Creado por Verónica Rodríguez Aguilar de la Universidad Autónoma de Querétaro en un proyecto dirigido por la Dra. Sandra Luz Canchola Magdaleno docente de la lista universidad, con la colaboración de las prestigiosas universidades autónomas de Querétaro (UAQ), Chiapas (UNACH) y Aguascalientes (UAA).
      </p>
      <p class="mt-3 xl:mt-8">
        Estamos enfocados en resolver la problemática de la falta de recursos adecuados para la enseñanza-aprendizaje en Instituciones de Educación Superior. Con RSE, tendrás acceso a una amplia variedad de materiales educativos, con la funcionalidad de almacenamiento, búsqueda y descarga en un solo lugar.
      </p>
    </div>

  </div>
  <div class="h-1/2 xl:h-full xl:w-1/2 p-1 lg:p-3 xl:p-10">
    <div class="flex justify-center">
      <img src="/assets/images/landing/preview-1.png" alt="">
    </div>
  </div>
</div>

<div class="xl:h-3/4 w-full bg-rse-primary flex flex-col-reverse xl:flex-row xl:flex">
  <div class="h-1/2 xl:h-full w:full xl:w-4/6 xl:px-4 py-8">
    <div class="xl:flex md:flex md:justify-center  xl:justify-between">
      <img class="w-full md:w-3/6 p-2" src="/assets/images/landing/preview-2.png" alt="">
      <img class="w-full md:w-3/6 p-2" src="/assets/images/landing/preview-3.png" alt="">
    </div>
  </div>
  <div class=":xlh-full xl:w-1/2 p-4 xl:p-10 text-rse-light md:text-xl xl:text-lg">
    <p class="xl:mt-0">
      Nuestro sistema está diseñado con tecnologías web, para asegurar una experiencia de usuario fluida y eficiente. La centralización de la información y la optimización de la búsqueda son algunos de nuestros objetivos principales para brindarte una experiencia de uso óptima.
    </p>
    <p class="xl:mt-10">
      No te preocupes por la calidad de los recursos, nuestro sistema cuenta con un formato de metadatos resultante, para garantizar la autenticidad de la información. ¡Deja de perder tiempo buscando recursos educativos en diferentes lugares! Con RSE, tendrás todo lo que necesitas en un solo lugar gracias a la comunidad universitaria.    </p>
  </div>
</div>
<div class="xl:h-30 xl:w-full bg-rse-dark flex justify-center">
  <div class="h-full xl:w-5/12 p-4 xl:p-5">
    <p class="text-rse-light xl:text-lg text-center">
      Únete a nosotros en la búsqueda por mejorar la educación y descubre una nueva forma de aprendizaje con "Repositorio de Software Educativo".
    </p>
  </div>
</div>
<div class="xl:h-30 w-full bg-rse-primary flex justify-center">
  <div class="h-full xl:w-2/5 p-4 xl:p-10">
    <p class="text-rse-light xl:text-lg text-center">
      RSE utiliza tecnología necesaria para brindar una experiencia de usuario ágil y eficiente, permitiendo a las instituciones educativas preservar y acceder de manera efectiva a sus recursos de aprendizaje.
    </p>
  </div>
</div>
<div class="xl:h-30 w-full bg-rse-dark flex justify-center">
  <div class="xl:h-full xl:w-1/3 xl:p-10 flex justify-center">
    <div class="w-1/3 p-4 flex justify-center">
      <div class="rounded-full h-20 w-20 xl:h-28 xl:w-28 bg-slate-50 flex justify-center items-center">
        <img src="/assets/images/landing/logo-unach.png" class="w-8/12 xl:w-8/12 h-8/12 xl:h-8/12" alt="">
      </div>
    </div>
    <div class="w-1/3 p-4 flex justify-center">
      <div class="rounded-full h-20 w-20 xl:h-28 xl:w-28 bg-slate-50  flex justify-center items-center">
        <img src="/assets/images/landing/logo-uaa.png" class="w-8/12 h-8/12" alt="">
      </div>
    </div>
    <div class="w-1/3 p-4 flex justify-center">
      <div class="rounded-full h-20 w-20 xl:h-28 xl:w-28 bg-slate-50  flex justify-center items-center">
        <img src="/assets/images/landing/logo-uaq.png" class="w-7/12 h-7/12" alt="">
      </div>
    </div>
  </div>
</div>
