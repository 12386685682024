import { Component } from '@angular/core';
import { Config } from '../../config/global.values';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { WebRepositoryService } from '../../modules/shared/services/web-repository.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {

  user: any = {};

  constructor(
    private webRepositoryService: WebRepositoryService,
    private config: Config,
    private router: Router
    ){

  }

  public authUser(form: NgForm) {
    const user = form.value;
    const payload = {
      "email": user.email,
      "password": user.password,
    };

    this.webRepositoryService.authUser(payload).subscribe({
      next: (value) => {
        {
          localStorage.setItem(this.config.rseTokenStorage, value.access_token);
          localStorage.setItem(this.config.rseUserName, value.user_name);
          localStorage.setItem(this.config.rseUserUniversity, value.user_university);
          localStorage.setItem(this.config.rseUserId, value.user_id);
          this.router.navigate(['/home']);
        }
      },
      error: (err: Error) => {
        Swal.fire({
          title: 'Error',
          text: 'Ocurrio un error, verifica que los datos esten correctamente.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    });
  }
}
