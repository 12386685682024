import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WebRepositoryService } from '../shared/services/web-repository.service';
import { Material } from '../models/material';
import Swal from 'sweetalert2'


type Consulta = {
  categoria: number,
  filtro?: string,
  databases?: number | string,
  languages?: number | string,
  processes?: number | string,
  material_name: string
}
@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit {

  consulta: Consulta = {
    categoria: -1,
    filtro: 'Todas',
    databases: 'Todas',
    languages: 'Todas',
    processes: 'Todas',
    material_name: '',
  };

  @Input() showOnlyUserAuth: boolean = false;

  filtros: Array<{ name: string, id: number }[]> = [];

  isShowResults: boolean = false;

  materials: Array<Material> = [];

  constructor(
    private webRepoServ: WebRepositoryService
  ) {

  }

  ngOnInit() {
    this.filtros[3] = [
      { name: 'Objetos', id: 0 },
      { name: 'Juegos', id: 1 },
      { name: 'Componentes', id: 2 },
      { name: 'Sitios en internet', id: 3 },
      { name: 'Activos', id: 4 },
    ];
    this.getResouces();

    if(this.showOnlyUserAuth == true){
      this.findAllMaterial({
        userAuth: 'ok'
      });
    }

  }

  public onSendConsulta(form: NgForm) {
    const busqueda = form.value;
    if (busqueda.material_name == '') {
      busqueda.material_name = 'Todas';
    }
    const payload: {
      title?: string,
      languages?: string,
      databases?: string,
      processes?: string,
      userAuth?: string,

    } = {
      title: busqueda.material_name,
      languages: busqueda.languages,
      databases: busqueda.databases,
      processes: busqueda.processes,
      userAuth: this.showOnlyUserAuth ? 'ok' : 'no',
    }
    this.findAllMaterial(payload);
  }

  public findAllMaterial(payload: any){
    this.webRepoServ.findMaterials(payload).subscribe({
      next: (value) => {
        this.isShowResults = true;
        this.materials = value.data;
      },
      error: (err) => {
      }
    });
  }

  public getFiltros() {
    const index: number = this.consulta.categoria;
    return this.filtros[index];
  }

  public getResouces() {
    this.getProcess();// [0]
    this.getLanguages();// [1]
    this.getDatabases();// [2]
  }

  public getLanguages() {
    this.webRepoServ.languagesList().subscribe({
      next: (res) => {
        this.filtros[1] = res.data;

      },
      error: () => {
        Swal.fire({
          title: 'Error!',
          text: 'Do you want to continue',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    });
  }

  public getDatabases() {
    this.webRepoServ.databasesList().subscribe({
      next: (res) => {
        this.filtros[2] = res.data;

      },
      error: () => {
        Swal.fire({
          title: 'Error!',
          text: 'Do you want to continue',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    });
  }

  public getProcess() {
    this.webRepoServ.processesList().subscribe({
      next: (res) => {
        this.filtros[0] = res.data;

      },
      error: () => {
        Swal.fire({
          title: 'Error!',
          text: 'Do you want to continue',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    });
  }
}
