<nav class="bg-rse-primary border-gray-200 px-6 shadow-md sticky top-0 z-10">
  <div class="flex flex-wrap justify-between items-center">
    <div class="flex items-center ">
      <a routerLink="/" class="flex text-rse-light  items-center align-middle">
        <img
          loading="lazy"
          class="w-12 h-12 mr-2 "
          src="/assets/images/logo/logo.svg"
          alt="logo"
        />
        <h1 class="text-xs sm:text-sm xl:text-xl">
          Repositorio de software educativo
        </h1>
      </a>

    </div>
    <div class="flex items-center">
      <a
      routerLink="/login"
      class="text-rse-light bg-rse-medium text-sm md:text-lg font-medium  rounded-sm px-1.5 md:px-5 py-0.5 "
      [ngClass]="{'hidden': router.url != '/'}"
      >
        INICIAR
    </a>
      <button
        [ngClass]="{'hidden': router.url == '/'}"
        type="button"
        class="flex items-center"
        aria-expanded="false"
        type="button"
        (click)="changeOpen()"
        cdkOverlayOrigin
        #menuOverlay="cdkOverlayOrigin"
      >
      <img
      src="/assets/images/menu.svg"
      class="w-5 ml-2"
      [ngClass]="animation"
      >
      </button>

    </div>
  </div>
</nav>
<ng-template

cdkConnectedOverlay
[cdkConnectedOverlayOrigin]="menuOverlay"
[cdkConnectedOverlayHasBackdrop]="true"
[cdkConnectedOverlayOpen]="isOpen"
(backdropClick)="closeOverlay()"
>
<div
  class="z-50 my-4 text-base list-none bg-rse-primary rounded divide-y divide-gray-100 shadow"
>
  <div class="py-3 px-4" *ngIf="isLogin">
    <span *ngIf="name" class="block text-sm text-rse-light "
      >{{name}}</span
    >
    <span *ngIf="university"
      class="block text-sm font-medium text-rse-light truncate "
      >{{university}}</span
    >
  </div>
  <ul class="py-1" aria-labelledby="user-menu-button">
    <li *ngIf="!isLogin">
      <a
      routerLink="/login"
      (click)="closeOverlay()"
        class="block py-2 px-4 text-sm text-rse-light  "
        >Iniciar sesión</a
      >
    </li>
    <li *ngIf="isLogin">
      <a
      routerLink="/home"
      (click)="closeOverlay()"
        class="py-2 px-4 text-sm text-rse-light flex "
        >
        <img class="mr-2 w-3.5" src="/assets/images/utils/find-medium.svg" alt="">
        Buscar material</a
      >
    </li>
    <li *ngIf="isLogin">
      <a
      [routerLink]="['/home/','profile']"
      (click)="closeOverlay()"
        class="py-2 px-4 text-sm text-rse-light flex "
        >
        <img class="mr-2 w-3.5" src="/assets/images/utils/list-solid-medium.svg" alt="">

        Ver mi material cargado</a
      >
    </li>
    <li *ngIf="isLogin">
      <a
        routerLink="/upload"
        (click)="closeOverlay()"
        class="py-2 px-4 text-sm text-rse-light flex  "
        >
        <img class="mr-2 w-3.5" src="/assets/images/utils/arrow-up-from-bracket-solid-medium.svg" alt="">
        Cargar material nuevo</a
        >
    </li>
    <li *ngIf="isLogin && isAdmin">
      <a
        routerLink="/admin-panel"
        (click)="closeOverlay()"
        class="py-2 px-4 text-sm text-rse-light flex"
        >
        <img class="mr-2 w-3.5" src="/assets/images/utils/square-poll-horizontal-solid.svg" alt="">
        Panel de administración
        </a
      >
    </li>
    <li>
      <a *ngIf="isLogin"
        routerLink="/"
        (click)="closeOverlay()"
        class="py-2 px-4 text-sm text-rse-light flex"
        >
        <img class="mr-2 w-3.5" src="/assets/images/utils/circle-info-solid-medium.svg"  alt="">
        Acerca del proyecto</a
      >
    </li>
    <li *ngIf="isLogin">
      <a
        (click)="logout()"
        class="block py-2 px-4 text-sm text-rose-800   cursor-pointer"
        >Cerrar sesión</a
      >
    </li>
  </ul>
</div>
</ng-template>
