import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Config } from './config/global.values';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private config: Config
    ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = localStorage.getItem(this.config.rseTokenStorage);

    if (authToken && !req.url.includes('storage')) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
