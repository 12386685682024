import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() showModal: boolean = false;
  @Output() dataEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {

  }

  public onCloseModal(){
    this.dataEvent.emit(false);
  }

}
