import { Component, OnInit, Input, HostListener } from '@angular/core';
import { dataForSemesters } from '../../../user/config/empty-values-semeters';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit {

  public legendLabel = '';
  public xAxisLabel = '';
  public yAxisLabel = '';

  public width: number = 700;
  public height: number = 300;
  public view: [number, number]= [600, 400];
  public gradient = true;
  @Input()
  public showLegend = false;
  public showXAxisLabel = true;
  public showYAxisLabel = true;
  public showXAxis = true;
  public showYAxis = true;
  public timeline = true;
  public doughnut = true;
  public showLabels = true;
  public fitContainer: boolean = false;

  @Input() data: any [] = [];
  @Input() labels : {
    horizontal?: string,
    vertical?: string
  } = {};
  public chartWidth: number = 300;
  public chartHeight: number = 300;
  constructor() { }

  ngOnInit() {
    this.chartWidth = window.innerWidth * 0.3;
    if(this.data.length <= 0){
      this.data = dataForSemesters;
    }
    if(this.labels){
      this.yAxisLabel = this.labels.vertical || '';
      this.xAxisLabel = this.labels.horizontal || '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    if(event.target.innerWidth < 400){
      this.chartWidth = event.target.innerWidth * 0.8;

    }else if(event.target.innerWidth < 600) {
      this.chartWidth = event.target.innerWidth * 0.55;
    }
    else if(event.target.innerWidth < 800) {
      this.chartWidth = event.target.innerWidth * 0.44;
    }
  }




}
