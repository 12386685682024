import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Config } from '../../config/global.values';
import { Router } from '@angular/router';
import { WebRepositoryService } from '../../modules/shared/services/web-repository.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  universities: any[] = [];

  semesters: any[] = [];

  user: any = {
/*     "name": "John",
    "last_name": "Doe",
    "mother_last_name": "Smith",
    "email": "john4.doe@example.com",
    "password": "password",
    "password_confirmation": "password",
    "user_type_id": 1,
    "university_id": 1,
    "semester_id": 1 */
  };

  constructor(
    private webRepositoryService: WebRepositoryService,
    private config: Config,
    private router: Router) {
  }

  ngOnInit(): void {
    this.webRepositoryService.universitiesList().subscribe(response => {
      this.universities = response.data;
    });
    this.webRepositoryService.semestersList().subscribe(response => {
      this.semesters = response.data;
    });
  }

  public saveUser(form: NgForm) {
    const user = form.value;
    const payload = {
      "name": user.name,
      "last_name": user.last_name,
      "mother_last_name": user.mother_last_name,
      "email": user.email,
      "password": user.password,
      "password_confirmation": user.password_confirmation,
      "user_type_id": parseInt(user.user_type_id),
      "university_id": parseInt(user.university_id),
      "semester_id": parseInt(user.semester_id),
    };

    this.webRepositoryService.registerUser(payload).subscribe({
      next: (value) => {
        {
          localStorage.setItem(this.config.rseTokenStorage, value.access_token);
          localStorage.setItem(this.config.rseUserName, value.user_name);
          localStorage.setItem(this.config.rseUserUniversity, value.user_university);
          this.router.navigate(['/home']);
        }
      },
      error: (err: Error) => {
        Swal.fire({
          title: 'Error',
          text: 'El correo ya fue registrado con anterioridad.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    });
  }
}
