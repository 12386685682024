
<div class="w-full h-full ">
  <div class="w-11/12 xl:w-3/12 m-auto">
    <header class="pt-6 pb-2 flex justify-center items-center flex-col">
      <div class="w-40 h-40 rounded-full bg-[#070707] flex justify-center p-1">
        <img src="/assets/images/logo/logo.svg" alt="logo" class="w-32">
      </div>
      <h1 class="text-center text-rse-light font-semibold text-2xl pt-4 pb-8">
        Iniciar sesión
      </h1>
    </header>
    <div class="bg-rse-primary rounded shadow pt-4 pb-2 px-3 xl:px-12">
      <div class="mb-4">
        <form (ngSubmit)="authUser( f )" #f="ngForm" novalidate class="space-y-4">
          <div class="">
            <label for="email" class="text-rse-light text-lg">
              Correo Electronico
            </label>
            <input tabindex="1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required [(ngModel)]="user.email" name="email" type="email" class="w-full bg-gray-100 rounded border-2 border-gray-300">
          </div>
          <div class="">
            <div class="grid grid-cols-2 grid-auto-flow-column">
              <label for="password" class="text-rse-light text-lg">
                Contraseña
              </label>
              <a tabindex="4" routerLink="/forgot-password" class="self-end text-right text-rse-medium">Olvide mi contraseña</a>
            </div>

            <input tabindex="2" minlength="8" required [(ngModel)]="user.password"name="password" type="password" class="w-full bg-gray-100 rounded border-2 border-gray-300">
          </div>
          <div>
            <app-btn [disabled]="!f.valid" tabindex="3" typeBtn="submit" bgColor="rse-medium" textColor="rse-light">Iniciar Sesión</app-btn>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-rse-primary rounded shadow mt-6 pt-4 pb-2 px-12">
      <div class="mb-4">
        <div class=" grid justify-center">
          <span class="text-rse-light text-lg text-center">
            ¿Aun no tienes una cuenta?
          </span>
          <a tabindex="5" routerLink="/register" class="text-rse-medium text-lg text-center">
            Crea una cuenta aqui
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
