<div class="bg-white w-full flex justify-center rounded-lg">
  <ngx-charts-bar-vertical
    *ngIf="showLegend == false"
    [view]="[chartWidth, chartHeight]"
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [legendTitle]="legendLabel"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
  >
  </ngx-charts-bar-vertical>

  <ngx-charts-bar-vertical-2d
  *ngIf="showLegend == true"
    [view]="view"
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [legendTitle]="legendLabel"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
  >
  </ngx-charts-bar-vertical-2d>
</div>

