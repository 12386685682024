<a [routerLink]="['/material/',material.id]">
  <div  class="relative  rounded-md  w-full h-full bg-rse-light px-2 pt-8 pb-8">
    <div class="flex md:flex-wrap absolute  top-0 right-0 w-32 md:w-auto overflow-x-auto h-1/3">
      <app-tag class="ml-1 mt-1" *ngFor="let label of labels" [label]="label"></app-tag>
    </div>
    <label class="font-semibold xl:text-lg"> {{material.title}} </label>
    <p class="">
      {{material.description| titlecase | slice:0:1 }}{{ material.description | slice:1 }}
    </p>
    <div class="text-sm absolute p-1 bottom-0 right-0">
      Fecha:
      <span class="font-medium">
        {{material.creation_date | date:'shortDate'}}
      </span>
      Autor:
      <span class="font-medium">
         {{material.user?.name }} {{material.user?.last_name }}
      </span>
    </div>
  </div>
</a>


