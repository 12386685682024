import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse  } from '@angular/common/http';
import { filter, map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WebRepositoryService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  get headers() {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }
//#region Lists

  public universitiesList(): Observable<any>{
    return this.http.get(`${this.apiUrl}/universities`);
  }

  public semestersList(): Observable<any>{
    return this.http.get(`${this.apiUrl}/semesters`);
  }

  public databasesList(): Observable<any>{
    return this.http.get(`${this.apiUrl}/databases`);
  }

  public processesList(): Observable<any>{
    return this.http.get(`${this.apiUrl}/processes`);
  }

  public languagesList(): Observable<any>{
    return this.http.get(`${this.apiUrl}/languages`);
  }

  //#endregion List

  public saveMaterial(material: any): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.apiUrl}/material/create`, material);
  }

  public updateMaterial(material: any, id :number): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.apiUrl}/material/update/${id}`, material);
  }

  public findMaterials({title, languages, databases, processes, userAuth}: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/material/list/filtro/${title||'Todas'}/${languages||'Todas'}/${databases||'Todas'}/${processes||'Todas'}/${userAuth}`);
  }

  public findMaterialsUserAuth(): Observable<any> {
    return this.http.get(`${this.apiUrl}/material/list/user`);
  }

  public findMaterial(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/material/${id}`);
  }

  public deleteMaterial(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/material/delete/${id}`);
  }
//#region User

  public registerUser(user: any): Observable<any>{

    return this.http.post(`${this.apiUrl}/register`, user, {});
  }

  public authUser(user: any): Observable<any>{
    const headers = this.headers;
    return this.http.post(`${this.apiUrl}/login`, user, {headers});
  }

  public logoutUser(user: any): Observable<any>{
    const headers = this.headers;
    return this.http.post(`${this.apiUrl}/logout`, user, {headers});
  }

  public forgotPassword(user: any): Observable<any>{
    const headers = this.headers;
    return this.http.post(`${this.apiUrl}/forgot-password`, user, {headers});
  }

  public resetPassword(user: any): Observable<any>{
    const headers = this.headers;
    return this.http.post(`${this.apiUrl}/reset-password`, user, {headers});
  }

  public findUsers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/list`);
  }

  public findUsersfilter({name, university, semester}: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/list/filtro/${name || 'todo'}/${university}/${semester}`);
  }

  public findUser(id : number): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/${id}`);
  }

//#endregion


  public getFile(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, { responseType: 'arraybuffer' }).pipe(
      map(response => response)
    );
  }

  public findFile(id: number): Observable<ArrayBuffer | null > {

    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    const req = new HttpRequest('GET', `${this.apiUrl}/files/${id}`, null, {
      headers,
      responseType: 'arraybuffer',
      reportProgress: true
    });

    return this.http.request(req).pipe(
      filter(event => {
        return event.type === HttpEventType.DownloadProgress || event instanceof HttpResponse;
      }),
      map((event: any) => {
        if (event.type === HttpEventType.DownloadProgress && event.total) {

          return event;
        } else if (event instanceof HttpResponse) {
          console.log('Archivo descargado correctamente.');
          return event.body;
        }
        return null;
      })
    );
  }

  public registerDownloadFile(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/file/download/${id}`).pipe(
      map(response => response)
    );
  }

  public findFileName(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/files/name/${id}`).pipe(
      map(response => response)
    );
  }

}
