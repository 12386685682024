import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaterialListComponent } from './pages/material-list/material-list.component';
import { MaterialCreateComponent } from './pages/material-create/material-create.component';
import { MaterialViewComponent } from './pages/material-view/material-view.component';
import { MaterialComponent } from './material.component';
import { PdfFileViewComponent } from './pages/file/pdf-file-view/pdf-file-view.component';
import { CecionFileComponent } from './pages/file/cecion-file/cecion-file.component';
import { MaterialUpdateComponent } from './pages/material-update/material-update.component';

const routes: Routes = [
  { path: '', component: MaterialComponent },
  { path: 'home/:id', component: MaterialListComponent },
  { path: 'upload', component: MaterialCreateComponent },
  { path: 'update/:id', component: MaterialUpdateComponent },
  { path: 'material/:id', component: MaterialViewComponent },
  { path: 'file/:id', component: PdfFileViewComponent },
  { path: 'cecion-de-derechos', component: CecionFileComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MaterialRoutes {

}
