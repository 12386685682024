export const dataForSemesters = [
  {
    "name": "1er Semestre",
    "value": 0,

  },
  {
    "name": "2do Semestre",
    "value": 0,

  },
  {
    "name": "3er Semestre",
    "value": 0,

  },
  {
    "name": "4to Semestre",
    "value": 0,

  },
  {
    "name": "5to Semestre",
    "value": 0,

  },
  {
    "name": "6to Semestre",
    "value": 0,

  },
  {
    "name": "7mo Semestre",
    "value": 0,

  },
  {
    "name": "8vo Semestre",
    "value": 0,

  },
  {
    "name": "9no Semestre",
    "value": 0,

  },
  {
    "name": "10mo Semestre",
    "value": 0,

  }
];
