import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRequiredFieldsDirective]'
})
export class RequiredFieldsDirectiveDirective {

  constructor(private el: ElementRef) {
    const formFields = this.el.nativeElement.querySelectorAll('input, select, textarea');
    for (let i = 0; i < formFields.length; i++) {
      formFields[i].setAttribute('required', 'true');
    }
  }
}
