import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-icon',
  templateUrl: './btn-icon.component.html'
})


export class BtnIconComponent implements OnInit {

  @Input()
  typeBtn: 'button' | 'reset' | 'submit' = 'button';
  @Input() bgColor: Color = 'rse-medium';
  @Input() textColor: Color = 'rse-light';
  @Input() borderColor: Color = null;
  @Input() textSize: Size = null;
  constructor(){}

  ngOnInit(): void {
  }
  get styleClass() {
    return {
      'bg-rse-medium': this.bgColor == 'rse-medium',
      'bg-rse-primary': this.bgColor == 'rse-primary',
      'bg-rse-light': this.bgColor == 'rse-light',

      'text-rse-medium': this.textColor == 'rse-medium',
      'text-rse-primary': this.textColor == 'rse-primary',
      'text-rse-light': this.textColor == 'rse-light',
      'border-2': this.borderColor != null,
      'border-rse-medium': this.borderColor == 'rse-medium',
      'border-rse-primary': this.borderColor == 'rse-primary',
      'border-rse-light': this.borderColor == 'rse-light',

      'text-sm': this.textSize == 'text-sm',
      'text-base': this.textSize == 'text-base',
      'text-lg': this.textSize == 'text-lg',
      'text-xl': this.textSize == 'text-xl',
    }
  }
}

type Color = 'rse-medium' | 'rse-primary'| 'rse-light' | null | '';
type Size = 'text-sm' | 'text-base'| 'text-lg' | 'text-xl' | null | '';
