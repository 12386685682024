import { Component, Input, OnInit } from '@angular/core';
import { WebRepositoryService } from '../../../shared/services/web-repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Material } from 'src/app/modules/models/material';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';
import { descriptionsInputsForm } from './matarial.config';
import { of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
@Component({
  selector: 'app-material-create',
  templateUrl: './material-create.component.html',
  styleUrls: ['./material-create.component.scss']
})
export class MaterialCreateComponent implements OnInit {

  public distribution_agreement_file!: File | null;

  @Input()
  isUpdate: boolean = false;

  public files: File[] = [];

  public textInfo = descriptionsInputsForm;
  public material: Material = {};

  public materialId!: number;

  public resoucersData: {
    databases?: {name: string, checked?: boolean, id: number}[],
    languages?: {name: string, checked?: boolean, id: number}[],
    processes?: {name: string, checked?: boolean, id: number}[],
  }= {};

  constructor(
    private webRepoServ: WebRepositoryService,
     private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit(): void {
    this.getResouces();
    if(this.isUpdate){
      this.materialId = this.route.snapshot.params['id'];

      setTimeout(() => {
        this.getMaterial(this.materialId);
      }, 3000);
    }

  }

  public getResouces() {
    Swal.showLoading();

    this.webRepoServ.languagesList().pipe(
      concatMap(languages => {
        this.resoucersData.languages = languages.data;
        return this.webRepoServ.databasesList();
      }),
      concatMap(databases => {
        this.resoucersData.databases = databases.data;
        return this.webRepoServ.processesList();
      })
    ).subscribe({
      next: processes => {
        this.resoucersData.processes = processes.data;


        Swal.close(); // Cierra automáticamente la alerta de carga

      },
      error: () => {
        Swal.hideLoading();
        Swal.fire({
          title: 'Error!',
          text: 'No se pudieron cargar los datos',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    });
  }

  public handleCecionInput(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.distribution_agreement_file = fileList.item(0);
    }
  }


  public handleFilesInput(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.files = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (file && file.type === 'application/pdf') {
          this.files.push(file);
        }
      }
    }
  }

  public onSaveMaterial(form: NgForm) {
    let formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append('files[]', this.files[i], this.files[i].name);
    }
    if (this.distribution_agreement_file) {
      formData.append('distribution_agreement_file', this.distribution_agreement_file, this.distribution_agreement_file.name);
    }
    const payload:{[key: string]: string|undefined | number[]} = {
      title: this.material.title,
      type: this.material.type,
      creation_date: this.material.creation_date,
      description: this.material.description,
      version_number: ''+this.material.version_number,
      display_type: this.material.display_type,
      interface: this.material.interface,
      services: this.material.services,
      data_source: this.material.data_source,
      preconditions: this.material.preconditions,
      functionality: this.material.functionality,
      system: this.material.system,
      github_link: this.material.github_link,
      distribution: this.material.distribution,
      reutility: this.material.reutility,
      languages: this.material.languages,
      databases: this.material.databases,
      processes: this.material.processes,
    };
    console.table(payload);

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key] ;
        if(value){
          formData.append(key, value.toString());
        }
      }
    }

    if(this.isUpdate){
      this.updateMaterial(formData);
    }else{
      this.saveMaterial(formData);
    }
  }

  private saveMaterial(formData: FormData){
    this.webRepoServ.saveMaterial(formData).subscribe({
      next: (value) => {
        Swal.fire({
          title: 'Material cargado',
          text: 'Se cargo el material correctamente',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      },
      complete: () => {
        this.router.navigate(['/home/profile']);
      },
      error: (err) => {
        Swal.fire({
          title: 'Error!',
          text: 'Do you want to continue',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    });
  }

   private updateMaterial(formData: FormData){
    this.webRepoServ.updateMaterial(formData, this.materialId).subscribe({
      next: (value) => {

      },
      complete: () => {
        this.router.navigate(['/home/profile']);
      },
      error: (err) => {
        Swal.fire({
          title: 'Error!',
          text: 'Do you want to continue',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    });
  }

  public setLanguages(event$: any){
    this.material.languages= event$;
  console.log(`%c 🕚: MaterialCreateComponent -> setLanguages -> event `, `font-size:20px;background-color:#12aae8;color:white;`, event$);
  }
  public setProcesses(event$: any){
    this.material.processes= event$;
  console.log(`%c 🕚: MaterialCreateComponent -> setLanguages -> event `, `font-size:20px;background-color:#12aae8;color:white;`, event$);
  }
  public setDatabases(event$: any){
    this.material.databases= event$;
  console.log(`%c 🕚: MaterialCreateComponent -> setLanguages -> event `, `font-size:20px;background-color:#12aae8;color:white;`, event$);
  }

  private getMaterial(id: number){
    this.webRepoServ.findMaterial(id).subscribe({
      next: (value) =>{
      this.material = value.data;

      this.resoucersData.languages?.map(item=>{

        if(this.material.languages?.find((value: any)=> value.id == item.id) != undefined){
          item.checked = true;
        }
        return item;
      });

      this.resoucersData.databases?.map(item=>{

        if(this.material.databases?.find((value: any)=> value.id == item.id) != undefined){
          item.checked = true;
        }
        return item;
      });

      this.resoucersData.processes?.map(item=>{

        if(this.material.processes?.find((value: any)=> value.id == item.id) != undefined){
          item.checked = true;
        }
        return item;
      });

      this.material.languages = this.material.languages?.map((value: any)=>{
        return value.id;
      });
      this.material.databases = this.material.databases?.map((value: any)=>{
        return value.id;
      });
      this.material.processes = this.material.processes?.map((value: any)=>{
        return value.id;
      });

      this.material.creation_date =  new Date('2023-03-22T00:00:00.000000Z').toISOString().slice(0, 10);


      console.log(`%c 🎢: MaterialCreateComponent -> getMaterial -> this.material `, `font-size:20px;background-color:#2b90e5;color:white;`, this.material);


    },
      error: () =>{

      }
    })
  }


}

