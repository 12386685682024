import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialListComponent } from './pages/material-list/material-list.component';
import { MaterialViewComponent } from './pages/material-view/material-view.component';
import { MaterialCreateComponent } from './pages/material-create/material-create.component';
import { MaterialRoutes } from './material.routing';
import { MaterialComponent } from './material.component';
import { FormsModule } from '@angular/forms';
import { MaterialCardInfoComponent } from './components/material-card-info/material-card-info.component';
import { SharedModule } from '../shared/shared.module';
import { PdfFileViewComponent } from './pages/file/pdf-file-view/pdf-file-view.component';
import { CecionFileComponent } from './pages/file/cecion-file/cecion-file.component';
import { MaterialUpdateComponent } from './pages/material-update/material-update.component';

@NgModule({
  declarations: [
    MaterialListComponent,
    MaterialViewComponent,
    MaterialCreateComponent,
    MaterialComponent,
    MaterialCardInfoComponent,
    PdfFileViewComponent,
    CecionFileComponent,
    MaterialUpdateComponent

  ],
  imports: [
    CommonModule,
    MaterialRoutes,
    FormsModule,
    SharedModule,
  ],
  exports: [
    MaterialCardInfoComponent,
  ]
})
export class MaterialModule { }
