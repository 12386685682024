<div class="form-group">
  <label for="mySelect">Select multiple</label>
  <div class="form-multiselect block w-full mt-1 rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" ngClass="border-gray-300 rounded-md">
    <select multiple class="hidden" id="mySelect" [(ngModel)]="selectedOptions">
      <option *ngFor="let option of options" [value]="option">{{option}}</option>
    </select>
    <div class="select-multiple-container">
      <div class="form-check" *ngFor="let option of options">
        <input class="form-check-input" type="checkbox" id="{{option}}" [checked]="selectedOptions.includes(option)" (change)="onSelectOption($event, option)">
        <label class="form-check-label" for="{{option}}">{{option}}</label>
      </div>
    </div>
  </div>
</div>
