<div class="w-full h-full flex justify-center">
  <form (ngSubmit)="onSaveMaterial( f )" #f="ngForm" enctype="multipart/form-data"
    class="w-11/12 xl:w-9/12 bg-rse-light text-rse-dark mt-4 xl:mt-10 rounded p-3 xl:pr-8 xl:mb-10">
    <h3 class="xl:text-xl font-semibold">Carga de material</h3>
    <div class="w-full xl:flex ">
      <div class="w-12/12 xl:w-1/6">
        <h3>Información general</h3>
      </div>
      <div class="w-11/12 xl:w-5/6 bg-slate-50 rounded">
        <div class="flex flex-col p-3 xl:pr-8">
          <label class="flex" for="description">
            Descripción
            <app-overlay-info [text]="textInfo.description" class="p-1"></app-overlay-info>
          </label>
          <textarea appRequiredField required [(ngModel)]="material.description"
            class="w-full xl:w-10/12 h-28 xl:h-20 rounded" name="description" cols="30" rows="10"></textarea>
        </div>
        <div class="grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2  xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class=" xl:flex xl:flex-col">
              <label class="flex" for="type">
                Tipo de material
                <app-overlay-info [text]="textInfo.type" class="p-1"></app-overlay-info>
              </label>
              <select appRequiredField required [(ngModel)]="material.type" class="w-full rounded" name="type">
                <option>
                  Software Educativo
                </option>
                <option>
                  Arquitecura de software
                </option>
                <option>
                  Proceso de software
                </option>
                <option>
                  Documentativo
                </option>
              </select>
            </div>

          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="name">
                Nombre de material
                <app-overlay-info [text]="textInfo.title" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.title" class="w-full xl:ml-6 rounded" type="text"
                name="title">
            </div>

          </div>
          <div *ngIf="resoucersData.languages" class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="languages">
                Lenguajes
                <app-overlay-info [text]="textInfo.languages" class="p-1"></app-overlay-info>
              </label>

              <select *ngIf="resoucersData.languages" [(ngModel)]="material.languages" multiple
                class="hidden " name="languages" required >
                <option *ngFor="let language of resoucersData.languages" [value]="language.id">
                  {{
                  language.name
                  }}
                </option>
              </select>
              <app-select-multi (selectedValues)="setLanguages($event)" [items]="resoucersData.languages" class="w-full xl:ml-6 rounded "></app-select-multi>
            </div>

          </div>
        </div>
        <div class="grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2 xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="creation_date">
                Fecha de creación
                <app-overlay-info [text]="textInfo.creation_date" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.creation_date" name="creation_date" type="date"
                class="w-full rounded">
            </div>
          </div>
          <div class="xl:w-1/3">
            <div class=" xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="version_number">
                Versión
                <app-overlay-info [text]="textInfo.version_number" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.version_number" pattern="^\d+(\.\d+)*$"
                inputmode="decimal" class="w-full xl:ml-6 rounded" type="text" name="version_number">
            </div>
          </div>
          <div *ngIf="resoucersData.databases" class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="database">
                Base de datos
                <app-overlay-info [text]="textInfo.databases" class="p-1"></app-overlay-info>
              </label>
              <select appRequiredField required [(ngModel)]="material.databases" multiple name="databases"
                class="w-full md:h-14 xl:ml-6 rounded hidden">
                <option [value]="database.id" *ngFor="let database of resoucersData.databases">
                  {{
                  database.name
                  }}
                </option>

              </select>
              <app-select-multi (selectedValues)="setDatabases($event)" [items]="resoucersData.databases" class="w-full xl:ml-6 rounded "></app-select-multi>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full xl:flex mt-5">
      <div class="xl:w-1/6">
        <h3>Especificación de implementación</h3>
      </div>
      <div class="xl:w-5/6 bg-slate-50 rounded">
        <div class="grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2 xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="display_type">
                Tipo de visulización
                <app-overlay-info [text]="textInfo.display_type" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.display_type" type="text" class="w-full rounded"
                list="display_type" name="display_type">

              <datalist id="display_type">
                <option>
                  Diagramas
                </option>
                <option>
                  Tablas
                </option>
                <option>
                  Gráficos
                </option>
                <option>
                  Mapas
                </option>
                <option>
                  Infografías
                </option>
                <option>
                  Texto
                </option>
                <option>
                  Video
                </option>
              </datalist>
            </div>
          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="interface">
                Interfaz
                <app-overlay-info [text]="textInfo.interface" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.interface" type="text"
                class="w-full xl:ml-6 rounded" list="interface" name="interface">
              <datalist id="interface">
                <option>
                  Lenguaje natural
                </option>
                <option>
                  Lenguaje especializado
                </option>
                <option>
                  Gráfico de usuario
                </option>
                <option>
                  Otro
                </option>
              </datalist>
            </div>
          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="services">
                Servicios
                <app-overlay-info [text]="textInfo.services" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.services" type="text" name="services"
                class="w-full xl:ml-6 rounded">
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2 xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="data_source">
                Fuente de datos
                <app-overlay-info [text]="textInfo.data_source" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.data_source" type="text" name="data_source"
                class="rounded" list="source">
              <datalist id="source">
                <option>
                  Proyecto universitario
                </option>
                <option>
                  Trabajo autodidacta
                </option>
                <option>
                  Trabajo grupal
                </option>
                <option>
                  Otro
                </option>
              </datalist>

            </div>

          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="preconditions">
                Precondiciones
                <app-overlay-info [text]="textInfo.preconditions" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.preconditions" class="w-full xl:ml-6 rounded"
                type="text" list="preconditions" name="preconditions">
              <datalist id="preconditions">
                <option>
                  Ninguna
                </option>
                <option>
                  Agregar
                </option>
              </datalist>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full xl:flex mt-5">
      <div class="xl:w-1/6">
        <h3>Especificación no funcional</h3>
      </div>
      <div class="xl:w-5/6 bg-slate-50 rounded">
        <div class="grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2 xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="reutility">
                Grado de reutilización
                <app-overlay-info [text]="textInfo.reutility" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.reutility" type="text" name="reutility"
                list="reutility" class="w-full rounded">
              <datalist id="reutility">
                <option>
                  Total
                </option>
                <option>
                  Parcial
                </option>
              </datalist>
            </div>

          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="distribution">
                Distribución
                <app-overlay-info [text]="textInfo.distribution" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.distribution" class="w-full xl:ml-6 rounded"
                type="text" list="distribution" name="distribution">
              <datalist id="distribution">
                <option>Interno (uso educativo)</option>
                <option>Externo (público)</option>
              </datalist>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xl:flex mt-5">
      <div class="xl:w-1/6">
        <h3>Especificación de identificación</h3>
      </div>
      <div class="w-full xl:w-5/6 bg-slate-50 rounded">
        <div
          class="w-full grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2 xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="system">
                Sistema
                <app-overlay-info [text]="textInfo.system" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.system" class="w-full rounded" type="text"
                name="system">
            </div>

          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="functionality">
                Funcionalidad
                <app-overlay-info [text]="textInfo.functionality" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required [(ngModel)]="material.functionality" type="text" name="functionality"
                class="w-full xl:ml-6 rounded">
            </div>

          </div>
          <div *ngIf="resoucersData.processes" class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="process">
                Procesos en el desarrollo
                <app-overlay-info [text]="textInfo.process" class="p-1"></app-overlay-info>
              </label>
              <select appRequiredField required multiple [(ngModel)]="material.processes" name="processes" type="text"
                class="w-full xl:ml-6 rounded hidden">
                <option [value]="process.id" *ngFor="let process of resoucersData.processes">
                  {{process.name}}
                </option>
              </select>
              <app-select-multi (selectedValues)="setProcesses($event)" [items]="resoucersData.processes" class="w-full xl:ml-6 rounded "></app-select-multi>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xl:flex mt-5">
      <div class="xl:w-1/6">
        <h3>Material a cargar</h3>
      </div>
      <div class="w-full xl:w-5/6 bg-slate-50 rounded">
        <div class="grid grid-cols-1 auto-cols-auto sm:grid-cols-2 md:grid-cols-3 mx-auto gap-2 xl:flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="derechos">
                Cesión de derechos
                <app-overlay-info [text]="textInfo.check_agreement_file" class="p-1"></app-overlay-info>
              </label><!-- appRequiredField required [(ngModel)]="material.check_agreement_file" -->
              <div class="flex flex-wrap">
                <a routerLink="/cecion-de-derechos" target="_blank" class="mr-4 py-2 px-4 rounded  text-sm font-semibold text-rse-primary border-solid border-2 border-rse-primary mt-4">
                  Cesión de derechos
                </a>
                <div class="mt-4">
                  <input appRequiredField required type="checkbox"  [(ngModel)]="material.check_agreement_file" name="check_agreement_file" class="ring-2 checked:bg-rse-dark hover:bg-rse-dark hover:checked:bg-rse-dark checked:focus:bg-rse-dark" />
                  <label for="check_agreement_file" class="select-none cursor-pointer" (click)="material.check_agreement_file = !material.check_agreement_file " >
                    He leído la cesión de derechos adjunta en este espacio.
                  </label>
                </div>
                <div class="mt-3">
                  <input appRequiredField required type="checkbox"  [(ngModel)]="material.check_agreement_file2" name="check_agreement_file2" class="ring-2 checked:bg-rse-dark hover:bg-rse-dark hover:checked:bg-rse-dark checked:focus:bg-rse-dark" />
                  <label for="check_agreement_file2" class="select-none cursor-pointer" (click)="material.check_agreement_file2 = !material.check_agreement_file2 " >
                    Acepto los términos.
                  </label>
                </div>

              </div>
            </div>
          </div>
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="xl:ml-6 flex" for="files">
                Subir archivos
                <app-overlay-info [text]="textInfo.files" class="p-1"></app-overlay-info>
              </label>
              <input appRequiredField required name="files[]" (change)="handleFilesInput($event)" accept=".pdf" class="xl:ml-6 block w-full text-sm
              file:mr-4 file:py-2 file:px-4
              file:rounded file:border-1 file:cursor-pointer
              file:text-sm file:font-semibold
              file:bg-transparent
               file:text-rse-primary

              file:border-rse-primary mt-4
            " type="file" multiple>
            </div>
          </div>

        </div>
        <div class="flex p-3 xl:pr-8">
          <div class="xl:w-1/3">
            <div class="xl:flex xl:flex-col">
              <label class="flex" for="github_link">
                Enlace GitHub
                <app-overlay-info [text]="textInfo.github_link" class="p-1"></app-overlay-info>
              </label>
              <input pattern="^https?://github\.com/[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,38}[a-zA-Z0-9])?/[a-zA-Z0-9._-]+$" appRequiredField required [(ngModel)]="material.github_link" name="github_link" type="text"
                class="rounded">
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="w-full flex mt-5 justify-end">
      <app-btn [disabled]="!f.valid || material.check_agreement_file == false || material.check_agreement_file2 == false" typeBtn="submit" bgColor="rse-primary" textColor="rse-light">
        Guardar
      </app-btn>
    </div>
  </form>
</div>
