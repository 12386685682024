import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Config } from './config/global.values';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInGuard implements CanActivate {
  constructor(
    private router: Router,
    private config: Config,
    private authService: AuthService
    ) {}

  async canActivate(): Promise<boolean> {

    const token = localStorage.getItem(this.config.rseTokenStorage);

    if (token) {
      let valid = false;
      try {
        const response = await this.authService.checkTokenValidity(token).toPromise();
        console.log(`%c 😆: AuthInGuard -> response `, `font-size:20px;background-color:#ceec72;color:black;`, response);

        valid = false;

        this.router.navigate(['/home']);
      } catch (error) {
        valid = true;
        localStorage.setItem(this.config.rseTokenStorage, '');
        localStorage.setItem(this.config.rseUserName, '');
        localStorage.setItem(this.config.rseUserUniversity, '');
        localStorage.setItem(this.config.rseIsLogin, 'false');
      }

    return valid;
    } else {

      return true;
    }
  }
}
