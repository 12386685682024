import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { WebRepositoryService } from '../../services/web-repository.service';
import { Config } from '../../../../config/global.values';
import { AuthService } from '../../../../auth.service';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit{

  public isOpen = false;

  public openSub: Subject<boolean> = new Subject();
  public name: string | null = '';
  public university: string | null = '';
  public isLogin: boolean = false;
  public isAdmin: boolean = false;

  @ViewChild(CdkConnectedOverlay) public overlay!: CdkConnectedOverlay ;

  constructor(
    private webRepositoryService: WebRepositoryService,
    private config: Config,
    public  router: Router,
    private authService: AuthService){

  }

  ngOnInit(): void {


    this.authService.checkTokenValidity(this.config.rseTokenStorage).subscribe({
      next: () => {

        this.isLogin = true;
      },
      error: () => {
      this.isLogin = false;
      },//GIT
      complete: () => {
      }
    });
    this.openSub.subscribe({
      next: (value) => {
        this.name = localStorage.getItem(this.config.rseUserName);
        this.university = localStorage.getItem(this.config.rseUserUniversity);
        this.isOpen = value;
        this.isLogin = localStorage.getItem(this.config.rseIsLogin) =='true';
        this.isAdmin = localStorage.getItem(this.config.rseIsAdmin) =='true';
      }})
  }

  get animation() {
    return {
      'transform': this.isOpen,
      'transition': this.isOpen,
      'duration-500': this.isOpen,
      'rotate-180': this.isOpen,
    }
  }

  public changeOpen(): void {
    this.isOpen = !this.isOpen;
    this.openSub.next(this.isOpen);

  }

  public logout(){
    this.closeOverlay();
    this.isOpen = false;
    this.webRepositoryService.logoutUser(null).subscribe({
      next: (value) =>{
      this.router.navigate(['/login']);
      },
      error: (e) =>{

      }
    });
  }



 public closeOverlay() {
  this.isOpen = false;
  this.openSub.next(this.isOpen);
  this.overlay.overlayRef.detach();
}


}
