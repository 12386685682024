
<div  class="w-screen h-screen flex justify-center items-center flex-col">
  <a  href="/assets/docs/CARTA CESION DE DERECHOS.pdf" download="/assets/docs/CARTA CESION DE DERECHOS.pdf" download class="text-2xl align-middle border-rse-light border-2 p-2 rounded-lg flex font-semibold my-10 text-rse-light " >Ceción de derechos <img class="m-2" src="/assets/images/utils/circle-down-solid-white.svg"></a>
    <div class="w-10/12 h-5/6  flex justify-center overflow-auto">
      <object id="obj" class="w-full h-full"  type="application/pdf" data="/assets/docs/CARTA CESION DE DERECHOS.pdf" >
      <embed src="/assets/docs/CARTA CESION DE DERECHOS.pdf" type="application/pdf" />
    </object>
    </div>
</div>


