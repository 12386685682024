import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';


@Component({
  selector: 'app-overlay-info',
  templateUrl: './overlay-info.component.html',
  styleUrls: ['./overlay-info.component.scss']
})
export class OverlayInfoComponent implements OnInit {

  public isOpen = false;

  public openSub: Subject<boolean> = new Subject();

  @Input()
  public text: string = ''

  public infoIcon: string = '/assets/images/utils/circle-info-solid.svg';

  @ViewChild(CdkConnectedOverlay)
  public overlay!: CdkConnectedOverlay;

  constructor(

  ) {

  }

  ngOnInit(): void {

  }

  get animation() {
    return {
      'transform': this.isOpen,
      'transition': this.isOpen,
      'duration-500': this.isOpen,
      'rotate-180': this.isOpen,
    }
  }

  public changeOpen(): void {
    this.isOpen = !this.isOpen;
    this.openSub.next(this.isOpen);

  }

  public logout() {
    this.closeOverlay();
    this.isOpen = false;

  }

  public closeOverlay() {
    this.isOpen = false;
    this.openSub.next(this.isOpen);
    this.overlay.overlayRef.detach();
  }


}
