import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-overlay-options',
  templateUrl: './overlay-options.component.html',
  styleUrls: ['./overlay-options.component.scss']
})
export class OverlayOptionsComponent implements OnInit {

  public isOpen = false;

  public openSub: Subject<boolean> = new Subject();

  @Input()
  public text: string = ''

  public infoIcon: string = '/assets/images/utils/circle-info-solid.svg';

  @ViewChild(CdkConnectedOverlay)
  public overlay!: CdkConnectedOverlay;

  constructor() { }

  ngOnInit() {
  }

  public changeOpen(): void {
    this.isOpen = !this.isOpen;
    this.openSub.next(this.isOpen);

  }

  public logout() {
    this.closeOverlay();
    this.isOpen = false;

  }

  public closeOverlay() {
    this.isOpen = false;
    this.openSub.next(this.isOpen);
    this.overlay.overlayRef.detach();
  }

}
