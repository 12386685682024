
<div class="w-full h-full">
<div class="w-11/12 xl:w-3/12 m-auto">
    <header class="pt-6 pb-2 flex justify-center items-center flex-col">
      <div class="w-40 h-40 rounded-full bg-[#070707] flex justify-center p-1">
        <img src="/assets/images/logo/logo.svg" alt="logo" class="w-32">
      </div>
      <h1 class="text-center text-rse-light font-semibold text-2xl pt-4 pb-8">
        Crear una cuenta
      </h1>
    </header>
    <div class="bg-rse-primary rounded shadow pt-4 pb-2 px-3 xl:px-12">
      <div class="mb-4 text-rse-light">
        <form  (ngSubmit)="saveUser( f )" #f="ngForm" novalidate class="space-y-4">
          <div>
            <label for="name" class="text-rse-light text-lg">
              Nombre
            </label>
            <input appRequiredField required [(ngModel)]="user.name" name="name" type="text" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
          </div>
          <div class="">
            <label for="last_name" class="text-rse-light text-lg">
              Apellido Paterno
            </label>
            <input appRequiredField required [(ngModel)]="user.last_name" name="last_name" type="text" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
          </div>
          <div class="">
            <label for="mother_last_name" class="text-rse-light text-lg">
              Apellido Materno
            </label>
            <input appRequiredField required [(ngModel)]="user.mother_last_name" name="mother_last_name" type="text" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
          </div>
          <div class="">
            <label for="university" class="text-rse-light text-lg">
              Universidad
            </label>
            <select appRequiredField required [(ngModel)]="user.university_id" name="university_id" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
              <ng-container *ngFor="let university of universities">
                <option [value]="university.id" >
                  {{university.name}}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="">
            <label for="type_user" class="text-rse-light text-lg">
              Tipo de usuario
            </label>
            <select appRequiredField required [(ngModel)]="user.user_type_id" name="user_type_id" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
                <option value="1" >
                  Estudiante
                </option>
                <option value="2" >
                  Profesor
                </option>
            </select>
          </div>
          <div class="" *ngIf="user.user_type_id == 1">
            <label for="semester" class="text-rse-light text-lg">
              Semestre
            </label>
            <select appRequiredField required [(ngModel)]="user.semester_id" name="semester_id" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
              <ng-container *ngFor="let semester of semesters">
                <option [value]="semester.id" >
                  {{semester.number}}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="">
            <label for="email" class="text-rse-light text-lg">
              Correo electronico
            </label>
            <input appRequiredField pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required [(ngModel)]="user.email" name="email" type="email" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
          </div>
          <div class="">
            <label for="password" class="text-rse-light text-lg">
              Contraseña
            </label>
            <input [(ngModel)]="user.password" minlength="8" name="password" type="password" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
          </div>
          <div class="">
            <label for="password_confirm" minlength="8" class="text-rse-light text-lg">
              Confirmar contraseña
            </label>
            <input required  [(ngModel)]="user.password_confirmation" name="password_confirmation" type="password" class="w-full bg-gray-100 text-rse-dark rounded border-2 border-gray-300">
          </div>
          <span class="m-2 text-sm font-medium text-white"  *ngIf="user.password_confirmation && user.password_confirmation.length >= 0 && user.password_confirmation != user.password">
            Las contraseñas no coinciden.
          </span>
          <br>
          <span class="m-2 text-sm font-medium text-white"  *ngIf="user.password_confirmation && user.password_confirmation.length < 8 && user.password && user.password.length < 8 ">
            La contraseña debe tener por lo menos 8 caracteres.
          </span>
          <div>
            <app-btn typeBtn="submit" [disabled]="!f.valid || user.password != user.password_confirmation "bgColor="rse-medium" textColor="rse-light">Registrarme</app-btn>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-rse-primary rounded shadow mt-6 pt-4 pb-2 px-12">
      <div class="mb-4">
        <div class=" grid justify-center">
          <span class="text-rse-light text-lg text-center">
            ¿Ya tienes una cuenta?
          </span>
          <a routerLink="/login" class="text-rse-medium text-lg text-center">
            Inicia sesion aquí
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
