<div class="w-screen h-screen flex justify-center items-center flex-col">


  <button *ngIf="isLoadedFile == false" disabled
    class="text-2xl rounded-lg font-semibold my-10 cursor-pointer  border-rse-light border-2 p-2 flex text-rse-light items-center relative focus:outline-none">
    <div class="bg-blue-500 rounded-lg h-full absolute left-0 top-0" [style.width]="progress + '%'"
      [ngClass]="{'opacity-0': progress === 0, 'opacity-100': progress > 0}"></div>
    <span class="ml-4 z-20">Descargar PDF</span>
    <svg class="animate-spin ml-2 w-6 h-6 text-white z-20" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
      </path>
    </svg>
  </button>


  <a *ngIf="isLoadedFile == true" [href]="pdfSrc" (click)="registerDownload()" [download]="filename" class="my-10" download>
    <button disabled
      class="text-2xl cursor-pointer rounded-lg font-semibold  border-rse-light border-2 p-2 flex text-rse-light items-center relative focus:outline-none">
      <div class="bg-blue-500 rounded-lg h-full absolute left-0 top-0" [style.width]="progress + '%'"
        [ngClass]="{'opacity-0': progress === 0, 'opacity-100': progress > 0}"></div>
      <span class="ml-4 z-20">Descargar PDF</span>

      <img class="ml-2 w-6 animate-bounce" src="/assets/images/utils/circle-down-solid-white.svg">
    </button>
  </a>

  <div *ngIf="isLoadedFile == false"
    class="border border-rse-light  bg-white shadow rounded-md  w-10/12 h-5/6 md:w-2/6 md:h-full flex justify-center overflow-none">
    <div class="p-6 w-full mx-auto h-full">
      <div class="animate-pulse flex ">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-3 bg-slate-700 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-3 bg-slate-700 rounded col-span-2"></div>
              <div class="h-e bg-slate-700 rounded col-span-1"></div>
            </div>
            <div class="h-3 bg-slate-700 rounded"></div>
          </div>
        </div>
      </div>
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-3 bg-slate-700 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-3 bg-slate-700 rounded col-span-2"></div>
              <div class="h-3 bg-slate-700 rounded col-span-3"></div>
            </div>
            <div class="h-3 bg-slate-700 rounded"></div>
          </div>
        </div>
      </div>
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-3 bg-slate-700 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-3 bg-slate-700 rounded col-span-2"></div>
              <div class="h-3 bg-slate-700 rounded col-span-2"></div>
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-3 bg-slate-700 rounded col-span-1"></div>
              <div class="h-3 bg-slate-700 rounded col-span-2"></div>
            </div>
            <div class="h-3 bg-slate-700 rounded"></div>
          </div>
        </div>
      </div>
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-3 bg-slate-700 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-3 bg-slate-700 rounded col-span-2"></div>
              <div class="h-3 bg-slate-700 rounded col-span-1"></div>
            </div>
            <div class="h-3 bg-slate-700 rounded"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="  w-10/12 h-5/6 md:w-full md:h-full flex justify-center overflow-auto rounded-md ">
    <a [href]="pdfSrc" (click)="registerDownload()" [download]="filename" download>
      <canvas class="w-full overflow-auto" #pdfViewer></canvas>
    </a>
  </div>
  <!-- <object id="obj" class="w-full h-full"  type="application/pdf" [data]="pdfSrc" >
      <embed [src]="pdfSrc" type="application/pdf" />
    </object> -->


</div>
