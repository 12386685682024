
<div class="w-full h-full ">
  <div class=" w-11/12 xl:w-3/12 m-auto">
    <header class="pt-6 pb-2 flex justify-center items-center flex-col">
      <div class="w-40 h-40 rounded-full bg-[#070707] flex justify-center p-1">
        <img src="/assets/images/logo/logo.svg" alt="logo" class="w-32">
      </div>
      <h1 class="text-center text-rse-light font-semibold text-2xl pt-4 pb-8">
        Recuperar contraseña
      </h1>
    </header>
    <div class="bg-rse-primary rounded shadow pt-4 pb-2 px-3 xl:px-12">
      <div class="mb-4">
        <form (ngSubmit)="onSendEmail( f )" #f="ngForm"  novalidate class="space-y-4">
          <div class="">
            <label for="email" class="text-rse-light text-lg">
              Correo Electronico
            </label>
            <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  name="email"  [(ngModel)]="user.email"  type="email" class="w-full bg-gray-100 rounded border-2 border-gray-300">
          </div>
          <div>
            <app-btn typeBtn="submit" [disabled]="!f.valid"  bgColor="rse-medium" textColor="rse-light">Recuperar</app-btn>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-rse-primary rounded shadow mt-6 pt-4 pb-2 px-12">
      <div class="mb-4">
        <div class=" grid justify-center">
          <span class="text-rse-light text-lg text-center">
            ¿Aun no tienes una cuenta?
          </span>
          <a routerLink="/register" class="text-rse-medium text-lg text-center">
            Crea una cuenta aquí
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
